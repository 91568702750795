<div class="upload-main-container">
  <div>
    <h4 class="medium" style="color: var(--grey-950-color)">File Upload & Text entry</h4>
    <span class="body2 medium" style="color: var(--grey-700-color)">
      Upload here any documents you consider necessary for the RFP Assistant to analyse your RFP.
    </span>
  </div>

  <div class="file-upload-container">
    <span class="subtitle medium">
      Upload a file
    </span>
    <qmdragdrop class="dragdrop"
                [allowReUpload]=true
                [addImageText]="addImageText"
                [addFormatText]="addFormatText"
                (changePicture)="onFileInput($event)"
                *ngIf="!isPendingPost"
    >
    </qmdragdrop>

    <div *ngFor="let f of files; index as i">
      <qmfilesnippet [file]="f"
                     [isPending]="isPendingPost"
                     (deleting)="removeFileFromList(i)"
                     [editIcon]="false"
      >
      </qmfilesnippet>
    </div>
    <div class="button-container">
      <button class="primary large"
              (click)="postRfpFileDocuments()"
              [disabled]="files.length === 0" qm
              *ngIf="!isPendingPost"
      >
        Confirm File Import
      </button>
    </div>

  </div>

  <div class="line">
    <span class="body2">And / Or</span>
  </div>

  <div class="text-upload-container">
    <span class="subtitle medium">
      Create a text entry
    </span>
    <span class="body2" style="color: var(--grey-700-color)">
      Paste the content of an email, a discussion, or your own notes submission. All fields are mandatory.
    </span>

    <div class="option-container-input-text-field">
      <div class="body2 medium">Title</div>
      <input style="width: 100%;"
             [(ngModel)]="textTitle"
             [disabled]="isPendingPostText"
             qm>
    </div>

    <div class="option-container-input-text-field">
      <div class="body2 medium">Copy paste</div>

      <textarea style="width: 100%; height: 100px; padding: 12px 16px"
                [(ngModel)]="textContent"
                [disabled]="isPendingPostText"
                qm> </textarea>
    </div>

  </div>


  <div class="button-container">
    <button class="primary large"
            (click)="postRfpTextDocuments()"
            [disabled]="(!textContent || !textTitle)" qm
            [isPending]="isPendingPostText"
    >
      Confirm Text Entry
    </button>
  </div>


</div>
