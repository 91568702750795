import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {map, Observable, Subscription} from 'rxjs';
import {AuthService} from '@quantmetry/api-services';
import {ActivatedRoute, Router} from '@angular/router';
import {QmTopBarAuthParam} from "@quantmetry/ui-components/lib/components/qmtopbar/models/qmTopBarAuthParam";
import {Store} from "@ngrx/store";
import {createFetchUserAction} from "./store/user/user.actions";
import {environment} from "../environments/environment";
import NavigationItem from "@quantmetry/ui-components/lib/components/qmtopbar/models/navigationItem";
import {selectCanAccessTool, selectUser} from "./store/user/user.selectors";
import UserType from "@quantmetry/api-services/lib/services/auth/models/user.type";
import {selectAllRfps} from "./store/rfp-assistant/rfp-assistant.selectors";
import {RfpAssistantService} from "./services/api/rfp-assistant/rfp-assistant.service";
import {createSetRfpFetchInterval} from "./store/rfp-assistant/rfp-assistant.actions";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  observableAuthEvent: Subscription;
  userPicture: any;
  authParam: QmTopBarAuthParam;
  navigationItems: NavigationItem[] = [];
  lightTopBar = false;
  feedbackFormURL = "https://forms.office.com/pages/responsepage.aspx?id=Wq6idgCfa0-V7V0z13xNYZcsKaDlScxFrlBfB2QI_pFUQ0UzODJKQUtHTzJDWFA0S0hIMzFVQ1dXNiQlQCN0PWcu";
  helpURL = "https://capgemini.sharepoint.com/:b:/s/JustAI-EarlyAdopters/EcPSuSjjj21GnZgVuLxpuj0BzB_9c41oyt1CdyIKey6PCw?e=UetLxH";
  title = 'front_end';

  public canAccessTool: boolean = false;
  public pendingCanAccessTool: boolean = true;
  public user?: UserType;

  public tempFetchingRfpIds: number[] = [];
  public doneRfpIds: number[] = [];
  public displayRfpIds: number[] = [];


  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
    private store: Store,
    private rfpService: RfpAssistantService
  ) {
  }

  ngOnInit(): void {
    if (this.isDisplayOk()) {
      this.setNavigationItems();
    }
    this.authParam = this.initAuthParam(this.authService);
    this.observableAuthEvent = this.authService
      .getAuthListener()
      .subscribe(() => {
        this.changeDetectorRef.detectChanges();
        this.store.dispatch(createFetchUserAction());
      });
    this.store.select(selectUser).subscribe(res => {
      this.user = res;
    });
    this.store.select(selectCanAccessTool).subscribe(res => {
      this.canAccessTool = res;
      if (this.user) {
        this.pendingCanAccessTool = false;
      }
    });
    this.store.select(selectAllRfps).subscribe(res => {
      for (let rfpId of this.tempFetchingRfpIds) {
        if (res[rfpId] && res[rfpId].rfp.analysis_status === "UP_TO_DATE" && window.location.pathname != "/rfp-assistant/" + rfpId) {
          this.doneRfpIds.push(rfpId);
          setTimeout(() => {
            this.displayRfpIds.push(rfpId);
          }, 5);
        }
      }
      this.tempFetchingRfpIds = [];
      for (let rfp of Object.values(res)) {
        if (rfp.rfp.analysis_status === "TO_DO" || rfp.rfp.analysis_status === "IN_PROGRESS") {
          this.tempFetchingRfpIds.push(rfp.rfp.id);
        }
      }
    });
    this.rfpService.getRfps().subscribe(res => {
      for(let rfp of res.list_rfps){
        if(rfp.analysis_status === "TO_DO" || rfp.analysis_status === "IN_PROGRESS"){
          this.store.dispatch(createSetRfpFetchInterval(rfp.id, true));
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.observableAuthEvent) {
      this.observableAuthEvent.unsubscribe();
    }
  }


  login(): void {
    this.authService.login();
  }

  isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  logout(): void {
    this.authService.logout(environment.redirectUri);
  }

  initAuthParam(authService: AuthService): QmTopBarAuthParam {
    return {
      authListener: authService.getAuthListener(),
      getName(): Observable<string> {
        return authService.getUserInformations().pipe(
          map((res) => {
            return res.displayName;
          })
        );
      },
      isLoggedIn(): boolean {
        return authService.isLoggedIn();
      },
      onLogin(): void {
        authService.login();
      },
      onLogout(): void {
        authService.logout(environment.redirectUri);
      },
    };
  }

  navigateToPage(route: string) {
    this.router.navigate(['/' + route]);
    this.navigationItems.forEach((item) => {
      item.isSelected = false;
    });
  }

  openWindow(windowToOpen: string): void {
    const url: string = (windowToOpen == "feedback" ? this.feedbackFormURL : this.helpURL);
    window.open(url, '_blank');
  }

  onActivate(component: any) {
    this.lightTopBar = false;
    this.navigationItems.forEach(item => {
      let path: string = "";
      if (component.route && component.route.snapshot) {
        path = component.route.snapshot.routeConfig.path.split("/")[0];
      }
      item.isSelected = item.link === path;
    });
    if (component.openDetailsPage) {
      component.openDetailsPage.subscribe((res: any) => {
        this.lightTopBar = true;
      });
    }
    if (component.closeDetailsPage) {
      component.closeDetailsPage.subscribe((res: any) => {
        this.lightTopBar = false;
      });
    }
  }

  isDisplayOk() {
    return window.innerWidth > 1200;
  }

  setNavigationItems(): NavigationItem[] {
    return this.navigationItems = [
      {name: "Upload Doc", link: "new-asset", isSelected: false, isDisabled: false},
      {name: "Slide Finder", link: "slide-finder", isSelected: false, isDisabled: false},
      {name: "Doc Finder", link: "doc-finder", isSelected: false, isDisabled: false},
      {name: "Secure ChatGPT", link: "private-gpt", isSelected: false, isDisabled: false},
      {name: "RFP Assistant", link: "rfp-assistant", isSelected: false, isDisabled: false}
    ];
  }

  onResize(): void {
    if (this.isDisplayOk()) {
      this.setNavigationItems();
    } else {
      this.navigationItems = [];
    }
  }

  closeRfpToaster(rfpId: number) {
    this.doneRfpIds = this.doneRfpIds.filter(id => id !== rfpId);
  }

  discoverRfp(rfpId: number) {
    this.router.navigate(['rfp-assistant/' + rfpId]);
    this.doneRfpIds = this.doneRfpIds.filter(id => id !== rfpId);
  }
}
