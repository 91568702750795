<div class="top-bar">
  <button class="transparent" (click)="back.emit()" qm>
    <span class="qm-icon-arrow-left"></span>{{ backButtonText }}
  </button>
</div>
<div class="middle-container">
  <div class="details-container">
    <div class="title">
      {{ utils.getFileNameWithoutExtension(assetElement.name) }}
    </div>
    <div *ngIf="getSecStatusDescriptionAndColor()" class="sec-status-container">
      <div [class]="['sec-chip', 'sec-chip-color-' + getSecStatusDescriptionAndColor()?.color]"></div>
      <div class="sec-status">{{ assetElement.sec_status }}</div>
      <span class="qm-icon-information-circle-contained sec-tooltip-icon"></span>
      <pre class="sec-tooltip-description caption medium">{{ getSecStatusDescriptionAndColor()?.description }}</pre>
    </div>
    <div class="subtitle">
      {{ assetElement.account }}
    </div>
    <div class="asset-metadata-holder">
      <span *ngIf="assetElement && assetElement.industry" class="metadata">{{ assetElement.industry }}</span>
      <span *ngIf="assetElement && assetElement.capability_unit"
            class="metadata">{{ assetElement.capability_unit }}</span>
      <span *ngIf="assetElement && assetElement.capability_unit_l1"
            class="metadata">{{ assetElement.capability_unit_l1 }}</span>
    </div>
    <div class="contact" *ngIf="assetElement.opportunity_lead || assetElement.contact_person">
      <div class="title">
        Contacts
      </div>
      <div class="contact-element"
           (click)="contactTeams(assetElement.opportunity_lead)"
           *ngIf="assetElement.opportunity_lead">
        <div class="teams-icon">
          <img src="/assets/teams-icon.svg" alt="teams"/>
        </div>
        <div class="person">
          <div class="job">
            {{ opportunityLead.jobTitle }}
          </div>
          <div class="name">
            {{ opportunityLead.displayName }}
          </div>
        </div>
        <div class="arrow">
          <span class="qm-icon-arrow-right"></span>
        </div>
      </div>
      <div class="contact-element"
           (click)="contactTeams(assetElement.contact_person)"
           *ngIf="assetElement.contact_person">
        <div class="teams-icon">
          <img src="/assets/teams-icon.svg" alt="teams"/>
        </div>
        <div class="person">
          <div class="job">
            {{ contactPerson.jobTitle }}
          </div>
          <div class="name">
            {{ contactPerson.displayName }}
          </div>
        </div>
        <div class="arrow">
          <span class="qm-icon-arrow-right"></span>
        </div>
      </div>
    </div>
    <div class="button-container">
      <button qm class="transparent open" (click)="openAsset()">Open document</button>
      <button qm class="transparent copy-link" (click)="copyAsset()">
        <span class="qm-icon-link"></span>
        Copy link
      </button>
    </div>
  </div>
  <div class="slide-viewer">
    <img
      alt="slide"
      *ngIf="thumbnails.getThumbnail(assetElement.unique_id, currentSlideIndex, true)"
      [src]="thumbnails.getThumbnail(assetElement.unique_id, currentSlideIndex, true)">
    <div
      *ngIf="!thumbnails.getThumbnail(assetElement.unique_id, currentSlideIndex, true)"
      class="pending">
    </div>
    <div class="switch-container">
      <div class="switch-pill">
        <div class="link" (click)="getPreviousThumbnail()">
          <span class="qm-icon-chevron-left"></span>
        </div>
        <div class="link" (click)="getNextThumbnail()">
          <span class="qm-icon-chevron-right"></span>
        </div>
        <div class="number">
          {{ currentSlideIndex + 1 }}/{{ assetElement.slides_urls.length }}
        </div>
      </div>
    </div>
  </div>
</div>
