import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {environment} from "../../environments/environment";
import {map} from "rxjs/operators";
import {AuthService} from "@quantmetry/api-services";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  public teamsGroupIds?: string[];

  public constructor(public authService: AuthService, private router: Router) {
  }

  hasAccess(): boolean{
    const teamsGroupIds: string[] = environment.teamsGroupIds;
    let access: boolean;
    if(teamsGroupIds.length === 0){
      access = true;
    } else if(!this.teamsGroupIds){
      access = false;
    } else {
      access = this.teamsGroupIds.some(groupId => teamsGroupIds.includes(groupId));
    }
    if(!access){
      this.router.navigate(["/"]);
    }
    return access;
  }

  canActivate(): Observable<boolean> {
    if (environment.teamsGroupIds.length === 0) {
      return of(true);
    }
    if(this.teamsGroupIds != null){
      return of(this.hasAccess());
    }
    return this.authService.getUserGroupIds().pipe(map(teamsGroupIds => {
      this.teamsGroupIds = teamsGroupIds;
      return this.hasAccess();
    }));
  }

}
