import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {GetRfpResponse, RfpDocument} from "../../../services/api/rfp-assistant/model/model";
import {RfpAssistantService} from "../../../services/api/rfp-assistant/rfp-assistant.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DocFinderPageComponent} from "../../doc-finder-page/doc-finder-page.component";
import {AdminService} from "../../../services/api/admin/admin.service";
import {HttpClient} from "@angular/common/http";
import {DocFinderService} from "../../../services/api/doc-finder/doc-finder.service";
import {Store} from "@ngrx/store";
import {
  createDeleteRfpDocument, createPatchRfpAnalysisStatus,
  createPatchRfpName,
  createSetRfp,
  createSetRfpFetchInterval
} from "../../../store/rfp-assistant/rfp-assistant.actions";
import {selectRfp} from "../../../store/rfp-assistant/rfp-assistant.selectors";

@Component({
  selector: 'app-rfp-details',
  templateUrl: './rfp-details.component.html',
  styleUrls: ['./rfp-details.component.scss']
})
export class RfpDetailsComponent extends DocFinderPageComponent implements OnInit {

  rfpDetails: GetRfpResponse;
  isEditingRfpDocuments = false;
  isEditingRfpName = false;
  rfpName = "";
  selectedTab: string = 'Ask questions';
  isExpanded = false;
  @Output() changeRfpName: EventEmitter<string> = new EventEmitter<string>();
  showMoreButton: boolean = true;
  textContent: string;
  textTitle: string;
  isPopinOpen = false;
  textDocuments: RfpDocument[] = [];
  nonTextDocuments: RfpDocument[] = [];
  patchingRfpName: boolean;
  deletingRfpDocId: number[] = [];
  isRfpAnalysisPending: boolean;

  @ViewChild('summaryContainer') summaryContainer: ElementRef;


  constructor(
    public rfpAssistantService: RfpAssistantService,
    http: HttpClient,
    docFinderService: DocFinderService,
    adminService: AdminService,
    router: Router,
    route: ActivatedRoute,
    public store: Store
  ) {
    super(http, docFinderService, adminService, router, route);
  }

  override ngOnInit(): void {
    const rfpId = this.route.snapshot.paramMap.get('id');
    if (rfpId !== null && rfpId !== undefined) {
      const id = +rfpId;
      if (!isNaN(id)) {
        this.store.select((state) => selectRfp(state, id)).subscribe((res: GetRfpResponse | undefined) => {
          if (res) {
            this.getRfpDetails(res);
            if (
              (this.rfpDetails.rfp.analysis_status == "EMPTY") || (this.rfpDetails.rfp.analysis_status == "OUTDATED")
            ) {
              this.isEditingRfpDocuments = true;
            }
          } else {
            this.store.dispatch(createSetRfpFetchInterval(id, false));
          }
        })
      }
    }
  }

  getRfpDetails(rfp: GetRfpResponse) {
    this.rfpDetails = rfp;
    this.textDocuments = this.rfpDetails.documents.filter(doc => doc.file_type === 'text/plain');
    this.nonTextDocuments = this.rfpDetails.documents.filter(doc => doc.file_type !== 'text/plain');
    if (this.rfpDetails.rfp.abstract) {
      this.getRfpAssets(rfp.rfp.id);
    }
  }

  setRfpName(name: string) {
    this.rfpName = name;
  }


  deleteRfpDocument(documentId: number) {
    this.deletingRfpDocId.push(documentId);
    this.store.dispatch(createDeleteRfpDocument(this.rfpDetails.rfp.id, documentId));
    this.rfpAssistantService.deleteDocument(this.rfpDetails.rfp.id, documentId).subscribe(() => {
      this.deletingRfpDocId = this.deletingRfpDocId.filter(docId => docId != documentId);
    });
    this.store.dispatch(createSetRfp(this.rfpDetails));
  }

  documentToFile(document: RfpDocument): File {
    const file = new File([''], document.file_name);
    Object.defineProperty(
      file, 'size', {value: document.file_size_in_bytes, writable: false});
    return file;
  }

  postRefreshRfpAnalysis(rfpId: number) {
    this.isRfpAnalysisPending = true;
    this.isEditingRfpDocuments = false;
    this.store.dispatch(createPatchRfpAnalysisStatus(rfpId, "TO_DO"));
    this.rfpAssistantService.postRefreshRfpAnalysis(rfpId).subscribe((res) => {
      this.store.dispatch(createSetRfpFetchInterval(rfpId, true));
      this.isRfpAnalysisPending = false;
    });
  }

  patchRfpName(rfpId: number, name: string) {
    this.patchingRfpName = true;
    this.store.dispatch(createPatchRfpName(rfpId, name));
    this.rfpAssistantService.patchRfpName(rfpId, name).subscribe((res) => {
      this.patchingRfpName = false;
    });
  }

  getRfpAssets(rfpId: number) {
    this.rfpAssistantService.getRfpAssets(rfpId, this.numberOfElementsToRetrieve).subscribe({
      next: (res) => {
        this.assets = res.elements;
        this.query_abstract = res.query_abstract;
        this.vector_abstract = res.vector_abstract;
      },
      error: (err) => {
        console.error('Error fetching RFP assets', err);
      }
    });
  }

  selectTab(tab: string) {
    this.selectedTab = tab;
  }

  editText(document: RfpDocument) {
    this.rfpAssistantService.getRfpTextDocumentContent(this.rfpDetails.rfp.id, document.id).subscribe(res => {
      this.textContent = res.text_content;
      this.textTitle = res.text_title;
      this.deleteRfpDocument(document.id);
    })
  }

  navigateToAssetRetriever() {
    this.router.navigate(["/doc-finder/", this.rfpDetails.rfp.id])
  }

  showAllSummary() {
    this.isExpanded = !this.isExpanded;
  }

  isAnalysisEmpty(): boolean {
    return this.rfpDetails && (this.rfpDetails.rfp.analysis_status == "EMPTY" || this.rfpDetails.documents.length == 0);
  }

  isAnalysisPending(): boolean {
    return this.rfpDetails &&
      (this.rfpDetails.rfp.analysis_status == 'TO_DO' || this.rfpDetails.rfp?.analysis_status == 'IN_PROGRESS')
  }

  isAnalysisUpToDate(): boolean {
    return this.rfpDetails && this.rfpDetails.rfp.analysis_status == "UP_TO_DATE"
  }

  isAnalysisOutdated(): boolean {
    return this.rfpDetails && this.rfpDetails.rfp.analysis_status == "OUTDATED" && this.rfpDetails.documents.length > 0
  }

}
