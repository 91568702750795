<qmpopin
  [isCrossAvailable]="!isPendingPost"
  [darkmode]=false
  [scrollable]=true
  (close)="this.popinCloser.emit();"
  class="info-popin"
>
  <div class="popin-text-editing-container">
    <h3 style="margin: 0px;">Edit text</h3>

    <div class="text-editing-container">

      <div>
        <p class="body2 regular" style="margin-top: 10px;">
          All fields are mandatory.
        </p>
      </div>

      <div class="option-container-input-text-field">
        <div class="body2 medium">Title</div>
        <input style="width: 100%;"
               [(ngModel)]="textTitle"
               qm>
      </div>

      <div class="option-container-input-text-field">
        <div class="body2 medium">Copy paste</div>

        <textarea style="width: 100%; height: 100px; padding: 12px 16px"
                  [(ngModel)]="textContent"
                  qm> </textarea>
      </div>

    </div>

    <div>
      <button class="secondary large"
              style="margin-right: 16px"
              (click)="this.popinCloser.emit()" qm>
        Cancel
      </button>
      <button class="primary large"
              [disabled]="!textContent && !textTitle || isPendingPost  " qm>
        Confirm
      </button>
    </div>


  </div>

</qmpopin>
