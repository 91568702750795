import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {
  GetRfpAssetsResponse,
  GetRfpResponse,
  GetRfpsResponse,
  GetRfpTextDocumentContentResponse, PostRfpDocumentsResponse, PostRfpQuestionRequest,
  PostRfpRagResponse,
  PostRfpResponse, RfpDocument,
} from "./model/model";


@Injectable({
  providedIn: 'root'
})
export class RfpAssistantService {

  constructor(
    public http: HttpClient
  ) {
  }

  postRfp(): Observable<PostRfpResponse> {
    const body: {} = {}
    return this.http.post<PostRfpResponse>(environment.apiUrl + '/rfp-assistant', body);
  }

  getRfps(): Observable<GetRfpsResponse> {
    return this.http.get<GetRfpsResponse>(environment.apiUrl + '/rfp-assistant');
  }

  getRfp(id: number): Observable<GetRfpResponse> {
    return this.http.get<GetRfpResponse>(environment.apiUrl + '/rfp-assistant/' + id);
  }

  postRfpDocuments(RfpId: number, files: File[]): Observable<PostRfpDocumentsResponse> {
    const formData: FormData = new FormData();
    files.forEach((file) => {
      formData.append('documents', file);
    });
    return this.http.post<PostRfpDocumentsResponse>(environment.apiUrl + '/rfp-assistant/' + RfpId + '/documents', formData);
  }

  deleteRfp(id: number): Observable<void> {
    return this.http.delete<void>(environment.apiUrl + '/rfp-assistant/' + id);
  }

  deleteDocument(RfpId: number, documentId: number): Observable<void> {
    return this.http.delete<void>(environment.apiUrl + '/rfp-assistant/' + RfpId + '/documents/' + documentId);
  }

  getRfpTextDocumentContent(RfpId: number, documentId: number): Observable<GetRfpTextDocumentContentResponse> {
    return this.http.get<GetRfpTextDocumentContentResponse>(environment.apiUrl + '/rfp-assistant/' + RfpId + '/text_document_content/' + documentId);
  }

  patchRfpTextDocumentContent(
    RfpId: number, documentId: number, title: string, content: string
  ): Observable<RfpDocument> {
    return this.http.patch<RfpDocument>(
      environment.apiUrl + '/rfp-assistant/' + RfpId + '/text_document_content/' + documentId, {
        'text_title': title,
        'text_content': content
      });
  }

  postRefreshRfpAnalysis(rfpId: number): Observable<void> {
    return this.http.post<void>(environment.apiUrl + '/rfp-assistant/' + rfpId + '/refresh-analysis', {});
  }

  patchRfpName(RfpId: number, name: string): Observable<void> {
    let options = {params: new HttpParams().set("name", name)};
    return this.http.patch<void>(environment.apiUrl + '/rfp-assistant/' + RfpId + '/name', {}, options);
  }

  getRfpAssets(RfpId: number, limit: number): Observable<GetRfpAssetsResponse> {
    let options = {params: new HttpParams().set("limit", limit)};
    return this.http.get<GetRfpAssetsResponse>(
      environment.apiUrl + '/rfp-assistant/' + RfpId + "/rfp-assets",
      options
    );
  };

  askQuestionRfp(RfpId: number, text: string): Observable<PostRfpRagResponse> {
    const body: PostRfpQuestionRequest = {
      text: text,
      rfp_id: RfpId
    }
    return this.http.post<PostRfpRagResponse>(environment.apiUrl + '/rfp-assistant/' + RfpId + "/ask-question", body)
  }
}
