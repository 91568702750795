<app-header *ngIf="!expandedAssetElement"
            [headerTitle]="'RFP Assistant'"
            [headerDescription]="'Speed up the process of RFP response. Find everything you need quickly.'"
            [headerBackgroundColor]="'rfpAssistant'"
            [minimized]="true"
>
</app-header>
<div *ngIf="rfpDetails && !expandedAssetElement" class="rfp-details-main-container">
  <div class="rfp-details-center-container">
    <div class="rfp-details-left-container">
      <div class="section-header" style="position: relative">
        <div class="title-edit-icon">
          <h3 class="medium title-two-lines" style="margin: 0" *ngIf="!isEditingRfpName">
            {{ rfpDetails.rfp.name !== "" ? rfpDetails.rfp.name : "RFP's title" }}
          </h3>
          <button *ngIf="!isEditingRfpName && !patchingRfpName" qm class="tertiary" icon="edit-03"
                  (click)="isEditingRfpName=true;">
          </button>
        </div>
        <div *ngIf="isEditingRfpName" class="input-title">
          <div>
            <input [(ngModel)]="rfpName"
                   (ngModelChange)="setRfpName($event)"
                   [placeholder]="rfpDetails.rfp.name ? '' : 'Enter a title...'"
                   [ngStyle]="{'color': rfpDetails.rfp.name ? 'black' : 'gray'}"
                   [height]="54"
                   qm/>
          </div>
          <button class="confirm-button-container"
                  (click)="patchRfpName(rfpDetails.rfp.id, rfpName); isEditingRfpName=false;" qm
          >
            Confirm
          </button>
        </div>
      </div>

      <div *ngIf="isEditingRfpDocuments">
        <app-rfp-provide-info
          [rfpDetails]="rfpDetails"
          [textContent]="textContent"
          [textTitle]="textTitle"
        >
        </app-rfp-provide-info>
      </div>
      <div *ngIf="isAnalysisPending()" class="pending-state">
        <span class="qm-icon-loader-01 rotating"></span>
        <div class="subtitle medium" style="color: var(--black-color)">
          Information is being analysed...
        </div>
        <div class="subtitle regular" style="text-align: center">
          The analysis may take quite some time, so don't hesitate to consult justAI's other features.
          You will be notified when your new RFP analysis is ready.
        </div>
      </div>
      <div *ngIf="isAnalysisUpToDate() && !isEditingRfpDocuments" class="rfp-details-summary-container">
        <h4 class="summary-container medium" style="margin-top: 0;"
        >
          Summary
        </h4>
        <div #summaryContainer class="summary-content" [ngClass]="{ 'expanded': isExpanded }">
          <markdown>{{ rfpDetails.rfp.summary }}</markdown>
        </div>
        <div class="options-wrapper">
          <div class="see-more-container" (click)="showAllSummary()">
            <span class="body2 medium" *ngIf="showMoreButton">{{ !isExpanded ? 'See more' : 'See less' }}</span>
            <span *ngIf="showMoreButton"
                  [ngClass]="{'qm-icon-chevron-down': !isExpanded, 'qm-icon-chevron-up': isExpanded}"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="rfp-details-right-container">
      <div class="analysis-button-container">
        <button *ngIf="isEditingRfpDocuments && isAnalysisEmpty()"
                [disabled]="isAnalysisEmpty()"
                (click)="postRefreshRfpAnalysis(rfpDetails.rfp.id)"
                class="confirm-button-container"
                [isPending]="isRfpAnalysisPending"
                qm>
          Analyse
        </button>
        <button *ngIf="isEditingRfpDocuments && isAnalysisOutdated()"
                [disabled]="!isAnalysisOutdated()"
                (click)="postRefreshRfpAnalysis(rfpDetails.rfp.id)"
                class="confirm-button-container"
                [isPending]="isRfpAnalysisPending"
                qm>
          Apply Modifications
        </button>
        <button *ngIf="isEditingRfpDocuments && isAnalysisUpToDate()"
                (click)="isEditingRfpDocuments = false"
                class="confirm-button-container"
                qm
                [isPending]="isRfpAnalysisPending"
        >
          Go back to analysis
        </button>
      </div>
      <div class="uploaded-docs">
        <div class="uploaded-docs-header">
          <h4 class="medium" style="margin: 0; height: 40px">Uploaded documents</h4>
          <button *ngIf="isAnalysisUpToDate() && !isEditingRfpDocuments" qm class="secondary" icon="edit-03"
                  (click)="isPopinOpen = true">
            Edit
          </button>
        </div>
        <div class="docs-container-scrollable">
          <div *ngIf="rfpDetails.documents.length === 0" class="no-docs">
            No file uploaded yet.
          </div>
          <div class="non-text-documents"
               *ngIf="nonTextDocuments.length > 0"
               [ngClass]="textDocuments.length > 0 ?'line' : ''"
          >
            <div *ngFor="let document of nonTextDocuments; index as i">
              <qmfilesnippet [file]="documentToFile(document)"
                             [editIcon]="document.file_type === 'text/plain' && isEditingRfpDocuments"
                             [trashIcon]="isEditingRfpDocuments"
                             (deleting)="deleteRfpDocument(document.id)"
                             [isPending]="deletingRfpDocId.indexOf(document.id) > - 1"
              ></qmfilesnippet>
            </div>
          </div>

          <div class="text-documents">
            <div *ngFor="let document of textDocuments; index as i">
              <qmfilesnippet [file]="documentToFile(document)"
                             [editIcon]="document.file_type === 'text/plain' && isEditingRfpDocuments"
                             [trashIcon]="isEditingRfpDocuments"
                             (editing)="editText(document)"
                             (deleting)="deleteRfpDocument(document.id)"
                             [isPending]="deletingRfpDocId.indexOf(document.id) > - 1"
              ></qmfilesnippet>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isAnalysisUpToDate() && !isEditingRfpDocuments && !expandedAssetElement" class="rfp-details-bottom-container">
  <div class="tabs">
    <div class="tab body2 medium"
         [ngClass]="{'active': selectedTab === 'Ask questions',
         'body1': selectedTab === 'Ask questions',
         'semibold': selectedTab === 'Ask questions'}"
         (click)="selectTab('Ask questions')">Ask questions
    </div>
    <div class="tab body2 medium"
         [ngClass]="{'active': selectedTab === 'Asset retriever',
         'body1': selectedTab === 'Asset retriever',
         'semibold': selectedTab === 'Asset retriever'}"
         (click)="selectTab('Asset retriever')">Doc Finder
    </div>
    <div class="tab body2 medium"
         [ngClass]="{'active': selectedTab === 'Expert network',
         'body1': selectedTab === 'Expert network',
         'semibold': selectedTab === 'Expert network'
         }"
         (click)="selectTab('Expert network')">Expert network
    </div>
  </div>
  <div id="content">
    <div *ngIf="selectedTab === 'Ask questions'">
      <app-rfp-ask-question [rfp]="rfpDetails.rfp"></app-rfp-ask-question>
    </div>
    <div *ngIf="selectedTab === 'Asset retriever'">
      <div *ngIf="rfpDetails.documents.length === 0"> Add documents to retrieve the most related documents.</div>
      <div class="asset-retriever-top-container" *ngIf="rfpDetails.documents.length !== 0">
        <div class="abstract-container"></div>
        <button
          class="secondary seeMoreButton"
          iconPosition="right"
          icon="arrow-right"
          qm
          (click)="navigateToAssetRetriever()"
        >
          See more in doc finder
        </button>
      </div>
      <app-asset-elements-container *ngIf="rfpDetails.rfp.abstract"
                                    [assetElements]="assets"
                                    [pending]="isPending"
                                    [isLoadingMoreElements]="isLoadingMoreElements"
                                    [mode]="'Asset Retriever'"
                                    [yearFilter]="year_filter"
                                    (getNext)="getMoreElements()"
                                    (logOpenDocument)="logOpenDocument($event)"
                                    (viewDetails)="viewElementDetails($event)"
      ></app-asset-elements-container>
    </div>
    <div *ngIf="selectedTab === 'Expert network'">
      Coming soon
    </div>
  </div>
</div>

<app-asset-element-details
  *ngIf="expandedAssetElement"
  [assetElement]="expandedAssetElement.assetElement"
  [displayAfterAndBackButtons]="true"
  [backButtonText]="'Back to RFP assistant'"
  (back)="backToPrincipalPage()"
  (logOpenDocument)="logOpenDocument($event)"
>
</app-asset-element-details>

<qmpopin
  *ngIf="isPopinOpen"
  [isCrossAvailable]="true"
  (close)="isPopinOpen=false"
>
  <div class="popin-container">
    <h3 class="medium" style="text-align: center; margin: 0">
      Do you wish to change your docs ?
    </h3>
    <div class="body" style="color: var(--text-grey-extreme)">
      Adding, deleting or changing source docs in your RFP will necessarily trigger another analysis.
      <br><br>
      During this new analysis, the RFP assistant features won't be available.
    </div>
    <div class="button-popin">
      <button qm class="secondary"
              (click)="isPopinOpen=false">
        Cancel
      </button>
      <button qm class="primary"
              (click)="isEditingRfpDocuments=true; isPopinOpen=false">
        Confirm
      </button>
    </div>
  </div>
</qmpopin>
