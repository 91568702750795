import {
  Component,
  ElementRef, HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SecureChatGptService} from "../../services/api/secure-chatgpt/secure-chatgpt.service";
import {AuthService} from "@quantmetry/api-services";
import {Conversation, Message, PrivateGptConversation} from "../../services/api/secure-chatgpt/model/model";
import {ChatLlmComponent} from "../../components/chat-llm/chat-llm.component";
import {ActivatedRoute} from "@angular/router";


@Component({
  selector: 'app-secure-chatgpt',
  templateUrl: './secure-chatgpt.component.html',
  styleUrls: ['./secure-chatgpt.component.scss']
})

export class SecureChatGptComponent implements OnInit {

  conversation: Conversation = {messages: []};
  userPicture: string;
  isAnswerPending: boolean = false;
  lastQuestionReturnedError: boolean = false;

  displayHistory: boolean = false;
  conversations: PrivateGptConversation[] = [];
  resetButtonText: string = "New subject";
  placeholder: string = "Message..."

  isFetchingConversations: boolean = false;
  gpt4: boolean = false;
  isGpt4DropdownOpen: boolean = false;
  conversationType: string ="GENERIC";
  canSendMessage: boolean = true;
  link = "secure-chatgpt";
  conversationsGroupedByDate: { date: string, conversations: PrivateGptConversation[] }[] = [];

  constructor(
    public http: HttpClient,
    public privateGptService: SecureChatGptService,
    public authService: AuthService,
    public route: ActivatedRoute,
    public elementRef: ElementRef
  ) {
  };

  @ViewChild('messages') private messagesContainer: ElementRef;
  @ViewChild(ChatLlmComponent) chat_llm_component: ChatLlmComponent;

  ngOnInit(): void {
    this.getUserPicture();
    this.getConversations();
  };

  askQuestion(question: string) {
    if (this.lastQuestionReturnedError) {
      this.conversation.messages.pop();
      this.lastQuestionReturnedError = false;
    }
    this.isAnswerPending = true;
    this.canSendMessage = false;
    this.privateGptService.askQuestion(
      question, [...this.conversation.messages], this.gpt4, this.conversationType, this.conversation.id
    ).subscribe({
      next: (res) => {
        this.addAnswerAsMessageToConversation(res.response);
        this.conversation.id = res.conversation_id;
        this.isAnswerPending = false;
        this.canSendMessage = true;
        this.scrollToBottom();
      },
      error: () => {
        this.isAnswerPending = false;
        this.lastQuestionReturnedError = true;
        this.canSendMessage = true;

      }
    });
    this.addQuestionAsMessageToConversation(question);
    this.scrollToBottom();
  }

  addQuestionAsMessageToConversation(question: string): void {
    const message: Message = {
      text: question,
      isUser: true
    };
    this.addMessageToConversation(message);
  };

  addAnswerAsMessageToConversation(answer: string): void {
    const message: Message = {
      text: answer,
      isUser: false
    };
    this.addMessageToConversation(message);
  };

  addMessageToConversation(message: Message): void {
    this.conversation.messages.push(message);
  };

  resendLastMessage() {
    this.conversation.messages.pop();
    const lastQuestionMessage: Message | undefined = this.conversation.messages.pop();
    if (lastQuestionMessage) {
      const question = lastQuestionMessage.text;
      this.askQuestion(question);
    }
  };


  getUserPicture() {
    this.authService.getUserPicture().subscribe({
      next: (v) => {
        this.userPicture = v;
      },
      error: () => {
      }
    });
  };

  switchDisplayHistory(event: undefined | MouseEvent = undefined) {
    if (event) {
      event.stopImmediatePropagation();
    }
    this.displayHistory = !this.displayHistory;
    if (this.displayHistory) {
      this.getConversations();
    }
  }

  clickOnHistoryMask() {
    if (this.displayHistory) {
      this.switchDisplayHistory();
    }
  }

  getConversations() {
    this.conversations = [];
    this.isFetchingConversations = true;
    this.privateGptService.getConversations().subscribe(res => {
      this.conversations = res.conversations.reverse();
      this.isFetchingConversations = false;
      this.scrollToBottom();
      this.groupConversations();
    });
  }

  getConversation(conversationId: number) {
    this.resetConversation();
    this.privateGptService.getConversation(conversationId).subscribe(res => {
      this.conversation = {
        messages: res.messages.map(dbMessage => ({
          text: dbMessage.text,
          isUser: dbMessage.is_user
        })),
        id: '' + res.conversation.id
      }
    });
  }

  selectConversation(element: PrivateGptConversation) {
    if (this.displayHistory) {
      this.switchDisplayHistory();
    }
    this.getConversation(element.id);
  }

  deleteConversation(element: PrivateGptConversation) {
    this.conversations = this.conversations.filter(conv => conv.id != element.id);
    this.privateGptService.deleteConversation(element.id).subscribe();
    if (this.conversation && this.conversation.id == element.id.toString()) {
      this.resetConversation();
    }
  }

  resetConversation() {
    this.conversation = {messages: []};
    this.lastQuestionReturnedError = false;
    this.conversationType = "GENERIC";
    this.chat_llm_component.resetComponent();
  }

  scrollToBottom(): void {
    setTimeout(() => {
      if (this.messagesContainer && this.messagesContainer.nativeElement) {
        this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
      }
    }, 100);
  }

  toggleGpt4Model(enableGpt4: boolean) {
    this.gpt4 = enableGpt4;
    this.isGpt4DropdownOpen = false;
  }

  newGptExpertConversation(gptExpert: string) {
    let starter_prompt: string;
    if (gptExpert === "EXPERT_RESEARCH_BRIEF") {
      starter_prompt = "I need to prepare a primer.";
    } else {
      starter_prompt = "What is the market size of my opportunity?";
    }
    this.conversationType = gptExpert;
    this.askQuestion(starter_prompt);
  }
  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    const dropdownButton = this.elementRef.nativeElement.querySelector('.model-choice-button');
    const dropdownMenu = this.elementRef.nativeElement.querySelector('.model-choice-dropdown');

    if (dropdownMenu && !dropdownButton.contains(event.target) && !dropdownMenu.contains(event.target)) {
      this.isGpt4DropdownOpen = false;
    }
  }

  groupConversations() {
    this.conversationsGroupedByDate = [];
    const conversationsMap = new Map();

    this.conversations.forEach(conversation => {
      if (conversation && conversation.date) {
        const date = conversation.date.split('T')[0];
        if (!conversationsMap.has(date)) {
          conversationsMap.set(date, []);
        }
        conversationsMap.get(date).push(conversation);
      }
    });

    conversationsMap.forEach((conversations, date) => {
      this.conversationsGroupedByDate.push({
        date: date,
        conversations: conversations
      });
    });
  }

  getGroupedConversations() {
    const groupedConversations = this.conversationsGroupedByDate;
    if (groupedConversations && groupedConversations.length > 0) {
      return groupedConversations;
    }
    return [];
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return 'Today';
    }
    if (date.toDateString() === yesterday.toDateString()) {
      return 'Yesterday';
    }
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'long' }).toLowerCase();
    const year = date.getFullYear();
    const ordinalSuffix = this.getOrdinalSuffix(day);

    return `${day}${ordinalSuffix} of ${month} ${year}`;
  }


  getOrdinalSuffix(day: number): string {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }
  removeQuotes(value: string): string {
    if (value.startsWith('"')) {
      value = value.slice(1);
    }
    if (value.endsWith('"')) {
      value = value.slice(0, -1);
    }
    return value;
  }


}
