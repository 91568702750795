import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FileObj} from "@quantmetry/ui-components";
import {RfpAssistantService} from "../../../../services/api/rfp-assistant/rfp-assistant.service";
import {Router} from "@angular/router";
import {
  GetRfpResponse,
  PostRfpDocumentsResponse,
  PostRfpResponse
} from "../../../../services/api/rfp-assistant/model/model";
import {Store} from "@ngrx/store";
import {
  createPostRfpDocuments,
  createSetRfp,
  createSetRfpFetchInterval
} from "../../../../store/rfp-assistant/rfp-assistant.actions";


@Component({
  selector: 'app-rfp-provide-info',
  templateUrl: './rfp-provide-info.component.html',
  styleUrls: ['./rfp-provide-info.component.scss']
})
export class RfpProvideInfoComponent implements OnInit {

  files: File[] = [];
  addImageText = `<span class='body2' >Add file or drag and drop here<span/><br/>`;
  addFormatText = `<span class='caption' style='color:var(--text-grey-moderate) !important'>Accepted formats: ppt, docx, pdf<span/><br/>
                         <span class='caption' style='color:var(--text-grey-moderate) !important'>Maximum file size: 50 Mo<span/>`;
  isPendingPost = false;
  isPendingPostText = false;

  @Input() rfpDetails: GetRfpResponse;
  @Input() rfpCreation: boolean;
  @Input() textContent: string;
  @Input() textTitle: string;

  constructor(
    private router: Router,
    public RfpAssistantService: RfpAssistantService,
    public store: Store
  ) {
  }

  ngOnInit(): void {
  }


  onFileInput(fileObj: FileObj) {
    if (fileObj.item) {
      const file_item = fileObj.item
      const isFileAlreadyUploaded = this.files.some(
        (file: File) => file.name === file_item.name && file.size === file_item.size
      );
      if (isFileAlreadyUploaded) {
        return;
      }
      if (
        !file_item.name.endsWith(".pptx") &&
        !file_item.name.endsWith(".docx") &&
        !file_item.name.endsWith(".pdf") ||
        file_item.size > 50 ** 8 ||
        file_item.size == 0
      ) {
        setTimeout(() => {
        }, 1);
      } else {
        this.files.push(file_item);
      }
    }
  }

  removeFileFromList(fileId: number): void {
    if (fileId < this.files.length && fileId >= 0) {
      this.files.splice(fileId, 1);
    }
  }

  onPostRfpFileResponse(res: PostRfpDocumentsResponse, docType: string) {
    if (docType == "doc") {
      this.isPendingPost = false;
    } else if (docType == "text") {
      this.isPendingPostText = false;
    }
    this.store.dispatch(createPostRfpDocuments(this.rfpDetails.rfp.id, res.documents));
    this.store.dispatch(createSetRfpFetchInterval(this.rfpDetails.rfp.id, false));
  };

  postRfpFileDocuments() {
    this.isPendingPost = true;
    const providedFiles = [...this.files];
    if (providedFiles.length > 0) {
      this.RfpAssistantService.postRfpDocuments(this.rfpDetails.rfp.id, providedFiles).subscribe(res => {
        this.onPostRfpFileResponse(res, "doc");
        this.files = [];
      });
    }
  }

  postRfpTextDocuments() {
    this.isPendingPostText = true;
    var blob = new Blob([this.textContent], {type: 'text/plain'});
    const textFile = new File([blob], this.textTitle + ".txt", {type: 'text/plain'});
    this.RfpAssistantService.postRfpDocuments(this.rfpDetails.rfp.id, [textFile]).subscribe(res => {
      this.onPostRfpFileResponse(res, "text");
      this.textContent = "";
      this.textTitle = "";
    });
  }
}
