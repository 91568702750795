<div class="title">
  Upload documents to the Invent Knowledge Hub
</div>
<div class="content-holder">
  <div class="element-holder">
    <img src="/assets/new-asset/form-circle.svg"/>
    <div class="element-title">Quick and easy</div>
    <div class="element-subtitle">2 minutes to share your assets</div>

  </div>
  <div class="element-holder">
    <img src="/assets/new-asset/form-square.svg"/>
    <div class="element-title">Unified process</div>
    <div class="element-subtitle">Centralized and smart access to IKH documents</div>
  </div>
  <div class="element-holder">
    <img src="/assets/new-asset/form-pentagon.svg"/>
    <div class="element-title">Stronger together</div>
    <div class="element-subtitle">Contribute and be recognized as enabler</div>
  </div>
</div>
<div class="button-gpt-container">
  <button (click)="isAddingAsset=true" class="large transparent" qm>
    Add document
  </button>
  <div *ngIf="isAddingAsset">
    <qmpopin
      [darkmode]="true"
      (close)="onCrossClick()"
      [isCrossAvailable]="isPopinTopBarDisplayed(popinState)"
    >
      <div class="desktop-h2-medium"
           *ngIf="isPopinTopBarDisplayed(popinState)">
        Upload doc
      </div>
      <div class="main-container">
        <div *ngIf="popinState == PopinState.AssetTypeChoice">
          <div class="subtitle-container">
            Select the document type:
          </div>
          <div
            [class]="'radio-button-container ' + (selectedTrackAsset === AssetType.ProjectAsset? 'is-radio-selected': '')">
            <qmradios-button id="project-asset" (click)="selectedTrackAsset=AssetType.ProjectAsset"
                             [checked]="selectedTrackAsset==AssetType.ProjectAsset" [withoutBorders]="true">
              <div class="radio-button-text">
                <div class="radio-button-text-head-title">
                  Project asset
                </div>
                <div class="radio-button-text-detail">
                  Proposal, credential, deliverable
                </div>
              </div>
            </qmradios-button>
          </div>
          <div
            [class]="'radio-button-container ' + (selectedTrackAsset === AssetType.CompanyAsset? 'is-radio-selected': '')">
            <qmradios-button id="company-asset" (click)="selectedTrackAsset=AssetType.CompanyAsset"
                             [checked]="selectedTrackAsset==AssetType.CompanyAsset" [withoutBorders]="true">
              <div class="radio-button-text">
                <div class="radio-button-text-head-title">
                  Company asset
                </div>
                <div class="radio-button-text-detail">
                  Not related to specific project (study, methods, offers, trainings...)
                </div>
              </div>
            </qmradios-button>
          </div>
        </div>
        <div *ngIf="popinState == PopinState.AddFile" class="add-file-state">
          <div class="search-container">
            <div class="desktop-body2-medium ">
              Select the asset category
            </div>
            <div *ngIf="popinAssetTrack === AssetType.ProjectAsset" class="asset-category-container">
              <div class="radio-container" (click)="select( 'Proposals')">
                <input class="radio-button"
                       type="radio"
                       name="radioOption"
                       [checked]="uploadAssetCategory === 'Proposals'">
                Proposal
              </div>
              <div class="radio-container" (click)="select('Credentials')">
                <input class="radio-button"
                       type="radio"
                       name="radioOption"
                       [checked]="uploadAssetCategory === 'Credentials'">
                Credential
              </div>
              <div class="radio-container" (click)="select('Deliverables')">
                <input class="radio-button"
                       type="radio"
                       name="radioOption"
                       [checked]="uploadAssetCategory === 'Deliverables'">
                Deliverable
              </div>
              <div class="radio-container" (click)="select('Project Summary')">
                <input class="radio-button"
                       type="radio"
                       name="radioOption"
                       [checked]="uploadAssetCategory === 'Project Summary'">
                Project Summary
              </div>
            </div>
            <div *ngIf="popinAssetTrack === AssetType.CompanyAsset" class="asset-category-container">
              <div class="radio-container" (click)="select( 'Capgemini Studies')">
                <input class="radio-button"
                       type="radio"
                       name="radioOption"
                       [checked]="uploadAssetCategory === 'Capgemini Studies'">
                Capgemini Studies
              </div>
              <div class="radio-container" (click)="select('Invent Approaches or Methods')">
                <input class="radio-button"
                       type="radio"
                       name="radioOption"
                       [checked]="uploadAssetCategory === 'Invent Approaches or Methods'">
                Invent Approaches or Methods
              </div>
              <div class="radio-container" (click)="select('Training')">
                <input class="radio-button"
                       type="radio"
                       name="radioOption"
                       [checked]="uploadAssetCategory === 'Training'">
                Training
              </div>
              <div class="radio-container" (click)="select('Marketing and Offers')">
                <input class="radio-button"
                       type="radio"
                       name="radioOption"
                       [checked]="uploadAssetCategory === 'Marketing and Offers'">
                Marketing and Offers
              </div>
              <div class="radio-container" (click)="select('Knowledge Nugget')">
                <input class="radio-button"
                       type="radio"
                       name="radioOption"
                       [checked]="uploadAssetCategory === 'Knowledge Nugget'">
                Knowledge Nugget
              </div>
            </div>
            <div class="desktop-body2-medium ">
              Select the asset to upload
            </div>
          </div>
          <qmdragdrop class="dragdrop dark"
                      *ngIf="!file"
                      [addImageText]="addImageText"
                      [addFormatText]="addFormatText"
                      (changePicture)="onFileInput($event)">
          </qmdragdrop>
          <qmfilesnippet
            *ngIf="file"
            [darkMode]="true"
            [editIcon]="false"
            [file]="file"
            (deleting)="file=undefined"
          >
          </qmfilesnippet>
        </div>
        <div *ngIf="popinAssetTrack === AssetType.ProjectAsset">
          <div *ngIf="popinState === PopinState.SelectOpportunity">
            <div class="body2 regular"> Select the name of your project to prefill the upload form.
              Otherwise you will have to do it manually in the next step.
            </div>
            <div class="search-container">
              <div class="body2 regular" style="padding: 16px 0">
                {{ selectedOpportunity ? 'Related Project' : 'Search the related project (optional)' }}
              </div>
              <div *ngIf="selectedOpportunity" class="selected-opportunity">
                <div class="opportunity-container">
                  <div class="left-block">
                    <div class="opportunity-id desktop-body2-medium"
                         style="margin-bottom: 0;">
                      OP# {{ selectedOpportunity.opportunity_id }}
                    </div>
                    <div class="account">
                      {{ selectedOpportunity.account }}
                    </div>
                  </div>
                  <div class="right-block desktop-body2-medium text-cropped-twolines"
                       style="margin-bottom: 0;">
                    {{ selectedOpportunity.opportunity_name }}
                  </div>
                  <div class="delete-icon-block" (click)="deselectOpportunity()">
                  <span class="qm-icon-trash-02 trash-button"
                        [style.color]="'#8A9DFD'"
                        style="font-size: 24px">
                  </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="search-container" *ngIf="!selectedOpportunity">
              <input qm
                     type="text"
                     *ngIf="searchedOpportunities.length > 0"
                     [(ngModel)]="searchValue"
                     placeholder="Search by Opportunity ID, Account or Project Name"
                     class="desktop-body2-medium"
                     style="width: 100%; background-color: var(--neutral-950-color); color: white"
              >
              <qmspinner *ngIf="searchedOpportunities.length === 0"></qmspinner>
            </div>
            <div class="opportunities-container" *ngIf="!selectedOpportunity">
              <div *ngFor="let opportunity of getSearchedOpportinuties()"
                   (click)="selectOpportunity(opportunity)"
                   class="opportunity-container">
                <div class="left-block">
                  <div
                    class="desktop-body2-medium"
                    style="margin-bottom: 0;"
                  >
                    OP# {{ opportunity.opportunity_id }}
                  </div>
                  <div class="account">
                    {{ opportunity.account }}
                  </div>
                </div>
                <div
                  class="right-block desktop-body2-medium text-cropped-twolines"
                  style="margin-bottom: 0;"
                >
                  {{ opportunity.opportunity_name }}
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="popinState == PopinState.EditMetadata" class="edit-metadata-state">
            <div class="input-row">
              <div class="input-block input-block-2">
                <div class="input-title desktop-body2-medium ">Project title*</div>
                <input qm
                       [(ngModel)]="form['title'].content"
                       (change)="form['title'].valid=true"
                       placeholder="Project title"
                       [class.invalid]="!form['title'].valid"
                       class="desktop-body2-medium">
              </div>
              <div class="input-block input-block-1">
                <div class="input-title desktop-body2-medium ">Opportunity ID*</div>
                <input qm
                       [(ngModel)]="form['opportunity_id'].content"
                       (change)="form['opportunity_id'].valid=true"
                       placeholder="OP# xxxxxxxx"
                       [class.invalid]="!form['opportunity_id'].valid"
                       class="desktop-body2-medium">
              </div>
            </div>
            <div class="input-row">
              <div class="input-block input-block-1">
                <div class="input-title desktop-body2-medium ">Account*</div>
                <input qm
                       [(ngModel)]="form['account'].content"
                       (change)="form['account'].valid=true"
                       placeholder="Account"
                       [class.invalid]="!form['account'].valid"
                       class="desktop-body2-medium">
              </div>
            </div>
            <div class="input-row">
              <div class="input-block input-block-1">
                <div class="input-title desktop-body2-medium ">Project start date*</div>
                <input qm
                       [(ngModel)]="form['created_date'].content"
                       (change)="form['created_date'].valid=true"
                       placeholder="dd/mm/yyyy"
                       [class.invalid]="!form['created_date'].valid"
                       class="desktop-body2-medium">
              </div>
              <div class="input-block input-block-1">
                <div class="input-title desktop-body2-medium ">Industry*</div>
                <div class="dropdown">
                  <input qm
                         [(ngModel)]="form['industry'].content"
                         [class.invalid]="!form['industry'].valid"
                         class="desktop-body2-medium"
                         (focus)="form['industry'].showDropdown = true">
                  <div *ngIf="form['industry'].showDropdown" class="dropdown-menu">
                    <div
                      *ngFor="let industry of getSearchedIndustries()"
                      (click)="selectFieldValue('industry', industry)"
                      class="dropdown-element">
                      {{ industry }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-row">
              <div class="input-block input-block-1">
                <div class="input-title desktop-body2-medium ">Capability Unit*</div>
                <div class="dropdown">
                  <input qm
                         [(ngModel)]="form['capability_unit'].content"
                         (change)="form['capability_unit'].valid=true"
                         [class.invalid]="!form['capability_unit'].valid"
                         class="desktop-body2-medium"
                         (focus)="form['capability_unit'].showDropdown = true">
                  <div *ngIf="form['capability_unit'].showDropdown" class="dropdown-menu">
                    <div
                      *ngFor="let capability_unit of getSearchedCapabilityUnits()"
                      (click)="selectFieldValue('capability_unit', capability_unit)"
                      class="dropdown-element">
                      {{ capability_unit }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="input-block input-block-1">
                <div class="input-title desktop-body2-medium ">Capability Unit L1*</div>
                <div class="dropdown">
                  <input qm
                         [(ngModel)]="form['capability_unit_l1'].content"
                         (change)="form['capability_unit_l1'].valid=true"
                         [class.invalid]="!form['capability_unit_l1'].valid"
                         class="desktop-body2-medium"
                         (focus)="form['capability_unit_l1'].showDropdown = true">
                  <div *ngIf="form['capability_unit_l1'].showDropdown" class="dropdown-menu">
                    <div
                      *ngFor="let capability_unit_l1 of getSearchedCapabilityUnitsL1()"
                      (click)="selectFieldValue('capability_unit_l1', capability_unit_l1)"
                      class="dropdown-element">
                      {{ capability_unit_l1 }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-row">
              <div class="input-block input-block-2">
                <div class="input-title desktop-body2-medium ">Opportunity Lead*</div>
                <input qm
                       [(ngModel)]="form['opportunity_lead'].content"
                       (change)="form['opportunity_lead'].valid=true"
                       placeholder="surname.name@capgemini.com"
                       [class.invalid]="!form['opportunity_lead'].valid"
                       class="desktop-body2-medium">
              </div>
              <div class="input-block input-block-1">
                <div class="sec-container">
                  <div class="desktop-body2-medium ">SEC Status* </div>
                  <span class="qm-icon-information-circle-contained sec-tooltip-icon"></span>
                  <pre class="sec-tooltip-description caption medium">{{ secText }}</pre>
                </div>

                <div class="dropdown">
                  <input qm
                         [(ngModel)]="form['sec_status'].content"
                         (change)="form['sec_status'].valid=true"
                         [class.invalid]="!form['sec_status'].valid"
                         class="desktop-body2-medium"
                         (focus)="form['sec_status'].showDropdown = true">
                  <div *ngIf="form['sec_status'].showDropdown" class="dropdown-menu">
                    <div
                      *ngFor="let secStatus of validSecStatus"
                      (click)="selectFieldValue('sec_status', secStatus)"
                      class="dropdown-element">
                      {{ secStatus }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="popinAssetTrack === AssetType.CompanyAsset">
          <div *ngIf="popinState == PopinState.EditMetadata" class="edit-metadata-state">
            <div class="input-row">
              <div class="input-block input-block-1">
                <div class="input-title desktop-body2-medium ">Industry*</div>
                <div class="dropdown">
                  <input qm
                         [(ngModel)]="form['industry'].content"
                         (change)="form['industry'].valid=true"
                         [class.invalid]="!form['industry'].valid"
                         class="desktop-body2-medium"
                         (focus)="form['industry'].showDropdown = true">
                  <div *ngIf="form['industry'].showDropdown" class="dropdown-menu">
                    <div
                      *ngFor="let industry of getSearchedIndustries()"
                      (click)="selectFieldValue('industry', industry)"
                      class="dropdown-element">
                      {{ industry }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-row">
              <div class="input-block input-block-1">
                <div class="input-title desktop-body2-medium ">Asset creation date*</div>
                <input qm
                       [(ngModel)]="form['created_date'].content"
                       (change)="form['created_date'].valid=true"
                       placeholder="dd/mm/yyyy"
                       [class.invalid]="!form['created_date'].valid"
                       class="desktop-body2-medium">
              </div>
              <div class="input-block input-block-1">
                <div class="input-title desktop-body2-medium ">SEC Status*</div>
                <div class="dropdown">
                  <input qm
                         [(ngModel)]="form['sec_status'].content"
                         (change)="form['sec_status'].valid=true"
                         [class.invalid]="!form['sec_status'].valid"
                         class="desktop-body2-medium"
                         (focus)="form['sec_status'].showDropdown = true">
                  <div *ngIf="form['sec_status'].showDropdown" class="dropdown-menu">
                    <div
                      *ngFor="let secStatus of validSecStatus"
                      (click)="selectFieldValue('sec_status', secStatus)"
                      class="dropdown-element">
                      {{ secStatus }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-row">
              <div class="input-block input-block-1">
                <div class="input-title desktop-body2-medium ">Capability Unit*</div>
                <div class="dropdown">
                  <input qm
                         [(ngModel)]="form['capability_unit'].content"
                         (change)="form['capability_unit'].valid=true"
                         [class.invalid]="!form['capability_unit'].valid"
                         class="desktop-body2-medium"
                         (focus)="form['capability_unit'].showDropdown = true">
                  <div *ngIf="form['capability_unit'].showDropdown" class="dropdown-menu">
                    <div
                      *ngFor="let capability_unit of getSearchedCapabilityUnits()"
                      (click)="selectFieldValue('capability_unit', capability_unit)"
                      class="dropdown-element">
                      {{ capability_unit }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="input-block input-block-1">
                <div class="input-title desktop-body2-medium ">Capability Unit L1*</div>
                <div class="dropdown">
                  <input qm
                         [(ngModel)]="form['capability_unit_l1'].content"
                         (change)="form['capability_unit_l1'].valid=true"
                         [class.invalid]="!form['capability_unit_l1'].valid"
                         class="desktop-body2-medium"
                         (focus)="form['capability_unit_l1'].showDropdown = true">
                  <div *ngIf="form['capability_unit_l1'].showDropdown" class="dropdown-menu">
                    <div
                      *ngFor="let capability_unit_l1 of getSearchedCapabilityUnitsL1()"
                      (click)="selectFieldValue('capability_unit_l1', capability_unit_l1)"
                      class="dropdown-element">
                      {{ capability_unit_l1 }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="popinState == PopinState.Cancel" class="cancel-state">
          <span class="qm-icon-x-01 close-icon" style="font-size: 38px"></span>
          <div class="desktop-h2-medium" style="margin-bottom:32px">You will have to restart upload if you confirm
            cancellation
          </div>
          <div class="buttons-container">
            <button qm (click)="popinState = stateBeforeCancellation" class="cancel-button">Return to upload</button>
            <button qm (click)="resetPopin(); isAddingAsset=false">Confirm cancellation</button>
          </div>
        </div>
        <div *ngIf="popinState == PopinState.Done" class="analysing-file-state">
          <span class="qm-icon-check" style="font-size: 100px; color: var(--blue-500-color)"></span>
          <div class="desktop-h2-medium">
            Your document has been successfully uploaded, it will be available in JustAI in a few hours
          </div>
          <div class="subtitle desktop-body2-regular">
            <div class="buttons-container">
              <button qm (click)="resetPopin()">
                Upload new document
              </button>
              <button qm class="cancel-button" (click)="resetPopin() ; isAddingAsset = false">
                Maybe later
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="popinState == PopinState.AnalysingFile || popinState == PopinState.UploadingFile"
             class="analysing-file-state">
          <qmspinner></qmspinner>
          <div class="desktop-h2-medium">
            <span *ngIf="popinState == PopinState.AnalysingFile">Your asset is being analyzed</span>
            <span *ngIf="popinState == PopinState.UploadingFile">Your asset is being uploaded</span>
          </div>
          <div class="subtitle desktop-body2-regular">
            <span *ngIf="popinState == PopinState.AnalysingFile">Metadata are automatically generated</span>
          </div>
        </div>
      </div>
      <div class="buttons-container" *ngIf="popinState == PopinState.AssetTypeChoice">
        <button qm
                (click)="chooseAssetTrack(selectedTrackAsset)"
        >
          Next
        </button>
      </div>
      <div class="buttons-container" *ngIf="popinState == PopinState.AddFile">
        <button
          qm
          [disabled]="!file || !uploadAssetCategory"
          (click)="confirmUpload()"
        >
          Next
        </button>
      </div>
      <div class="buttons-container" *ngIf="popinState === PopinState.SelectOpportunity">
        <button
          qm
          (click)="popinState = PopinState.EditMetadata"
        >
          Next
        </button>
      </div>
      <div class="buttons-container"
           *ngIf="popinState === PopinState.EditMetadata">
        <button qm (click)="triggerCancellationPopup()" class="cancel-button">Cancel</button>
        <button qm (click)="confirmMetadata()">Confirm</button>
      </div>
    </qmpopin>
  </div>
</div>
