import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {RfpConversation, RfpMessage, SourceDocument, RfpModel} from "../../../../services/api/rfp-assistant/model/model";
import {RfpAssistantService} from "../../../../services/api/rfp-assistant/rfp-assistant.service";
import {AuthService} from "@quantmetry/api-services";
@Component({
  selector: 'app-rfp-ask-question',
  templateUrl: './rfp-ask-question.component.html',
  styleUrls: ['./rfp-ask-question.component.scss']
})
export class RfpAskQuestionComponent implements OnInit {

  @Input() rfp: RfpModel;

  conversation: RfpConversation = {messages: []};
  isAnswerPending: boolean = false;
  lastQuestionReturnedError: boolean = false;
  userPicture: string;
  placeholder = "Message...";
  IsSourceDropDown: boolean = false;
  canSendMessage: boolean = true;

  constructor(
    public RfpAssistantService: RfpAssistantService,
    public authService: AuthService,
  ) { }

  @ViewChild('messages') private messagesContainer: ElementRef;

  ngOnInit(): void {
    this.getUserPicture();
    this.getConversations();
  }

  getConversations() {
    this.RfpAssistantService.getRfpConversation(this.rfp.id).subscribe((res) => {
      this.conversation = {
        messages: res.messages.map(dbMessage => ({
          text: dbMessage.text,
          isUser: dbMessage.is_user,
        }))
      }
    })
  }

  askQuestion(question: string){
    if (this.lastQuestionReturnedError) {
      this.conversation.messages.pop();
      this.lastQuestionReturnedError = false;
    }
    this.isAnswerPending = true;
    this.canSendMessage = false;
    this.RfpAssistantService.askQuestionRfp(this.rfp.id, question).subscribe({
      next:(res) => {
        this.addAnswerAsMessageToConversation(res.answer, res.sources);
        this.isAnswerPending = false;
        this.canSendMessage = true;
        this.scrollToBottom()
      },
      error: () => {
        this.isAnswerPending = false;
        this.lastQuestionReturnedError = true;
        this.canSendMessage = true;
      }
    });
    this.addQuestionAsMessageToConversation(question);
    this.scrollToBottom();

  }

  addQuestionAsMessageToConversation(question: string): void {
    const message: RfpMessage = {
      text: question,
      isUser: true
    };
    this.addMessageToConversation(message);
  };

  addMessageToConversation(message: RfpMessage): void {
    this.conversation.messages.push(message);
  };

  addAnswerAsMessageToConversation(answer: string, sources: SourceDocument[]): void {
    const message: RfpMessage = {
      text: answer,
      isUser: false,
      sources: sources
    };
    this.addMessageToConversation(message);
  };

  resendLastMessage() {
    this.conversation.messages.pop();
    const lastQuestionMessage: RfpMessage | undefined = this.conversation.messages.pop();
    if (lastQuestionMessage) {
      const question = lastQuestionMessage.text;
      this.askQuestion(question);
    }
  }

  getUserPicture() {
    this.authService.getUserPicture().subscribe({
      next: (v) => {
        this.userPicture = v;
      },
      error: () => {
      }
    });
  };

  scrollToBottom(): void {
    setTimeout(() => {
      if(this.messagesContainer && this.messagesContainer.nativeElement){
        this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
      }
    }, 100);
  }
}
