import { Component, OnInit } from '@angular/core';
import {FormComponent, PopinState} from "./model/model";
import {AssetType, PostIKHProposalsMetadata, UploadAssetCategory} from "../../services/api/ikh-proposals/model/model";
import {FileObj} from "@quantmetry/ui-components";
import {IKHOpportunity} from "../../services/api/ikh-opportunities/model/model";
import {validSecStatus} from "../../services/api/slide-finder/model/model";
import {Utils} from "../../utils/utils";
import {IkhProposalsService} from "../../services/api/ikh-proposals/ikh-proposals.service";
import {IkhOpportunitiesService} from "../../services/api/ikh-opportunities/ikh-opportunities.service";
import * as moment from "moment/moment";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-new-asset-page',
  templateUrl: './new-asset-page.component.html',
  styleUrls: ['./new-asset-page.component.scss']
})
export class NewAssetPageComponent implements OnInit {

  isAddingAsset = false;
  PopinState = PopinState;
  popinState: PopinState = PopinState.AssetTypeChoice;
  stateBeforeCancellation: PopinState = PopinState.AddFile;
  file?: File;
  AssetType = AssetType;
  popinAssetTrack: AssetType = AssetType.ProjectAsset;
  selectedTrackAsset: AssetType = AssetType.ProjectAsset;
  uploadAssetCategory: UploadAssetCategory;
  addImageText = `<span class='desktop-body2-regular' style='color:white !important'>Add file or drag and drop here<span/><br/>`
  addFormatText = `<span class='legend'>Accepted formats: ppt only<span/><br/>
                         <span class='legend'>Maximum file size: 100 Mo<span/>`;
  selectedOpportunity?: IKHOpportunity;
  searchedOpportunities: IKHOpportunity[] = [];
  form: { [formComponentName: string]: FormComponent } = {};
  industries: string[];
  capabilityUnits: string[];
  capabilityUnitsL1: string[];
  validSecStatus = validSecStatus;
  searchValue: string = "";
  utils: Utils = new Utils();
  fileCorrelationID?: string;
  isFormValid: boolean;
  secText = "SEC 0 - Public:\n" +
    "-> Internal and external use free, also for mass communication\n" +
    "-> For project asset docs free targeted use only, no mass communication\n" +
    "SEC 1 - Company Confidential:\n" +
    "-> Internally free for reading and sharing within CG\n" +
    "-> Externally use according to set permission status only \n" +
    "SEC 2 - Restricted Doc:\n" +
    "-> Internally can only be shared on a “need to know” basis\n" +
    "-> External use of an entire SEC 2 document is strictly prohibited"

  constructor(
    public ikhProposalsService: IkhProposalsService,
    public ikhOpportunitiesService: IkhOpportunitiesService,
    public route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.ikhOpportunitiesService.searchIkhOpportunities("").subscribe(res => {
      this.searchedOpportunities = res.ikh_opportunities;
    });
    this.ikhProposalsService.getIkhProposalMetadata().subscribe(res => {
      this.industries = res.industries;
      this.capabilityUnits = res.capability_units;
      this.capabilityUnitsL1 = res.capability_unit_l1;
    });
  }

  onCrossClick(): void {
    if ((this.popinState === PopinState.AddFile && this.file) ||
      this.popinState === PopinState.SelectOpportunity ||
      this.popinState === PopinState.EditMetadata) {
      this.triggerCancellationPopup();
    } else {
      this.isAddingAsset = false;
      this.popinState = PopinState.AssetTypeChoice;
    }
  }

  triggerCancellationPopup(): void {
    this.stateBeforeCancellation = this.popinState;
    this.popinState = PopinState.Cancel;
  }

  select(option: UploadAssetCategory) {
    this.uploadAssetCategory = option;
  }

  onFileInput(fileObj: FileObj) {
    if (fileObj.item) {
      this.file = fileObj.item;
      if (
        !this.file.name.endsWith(".pptx") ||
        this.file.size > 10 ** 8 ||
        this.file.size == 0
      ) {
        setTimeout(() => this.file = undefined, 1);
      }
    } else {
      this.file = undefined;
    }
  }

  public fileByteToSizeString(nbbytes: number) {
    if (nbbytes > 1000 * 1000) {
      return Math.floor(nbbytes / (1000 * 1000)) + " MB";
    } else if (nbbytes > 1000) {
      return Math.floor(nbbytes / 1000) + " KB";
    }
    return Math.floor(nbbytes) + " B";
  }

  deselectOpportunity() {
    this.selectedOpportunity = undefined;
    this.initializeEmptyForm();
  }

  initializeEmptyForm(): void {
    if (this.popinAssetTrack === AssetType.ProjectAsset) {
      this.form['title'] = {content: "", isValid: (value) => !!value, valid: true};
      this.form['opportunity_id'] = {content: "OP# ", isValid: (value) => !!value, valid: true};
      this.form['account'] = {content: "", isValid: (value) => !!value, valid: true};
      this.form['opportunity_lead'] = {
        content: "",
        isValid: (value) => value.endsWith("@capgemini.com") && this.validateEmail(value),
        valid: true
      };
    }
    this.form['created_date'] = {content: "", isValid: (value) => this.validateDate(value), valid: true};
    this.form['industry'] = {
      content: "",
      isValid: (value) => !!value && this.industries.indexOf(value) > -1,
      valid: true
    };
    this.form['capability_unit'] = {
      content: "",
      isValid: (value) => !!value && this.capabilityUnits.indexOf(value) > -1,
      valid: true
    };
    this.form['capability_unit_l1'] = {
      content: "",
      isValid: (value) => !!value && this.capabilityUnitsL1.indexOf(value) > -1,
      valid: true
    };
    this.form['sec_status'] = {
      content: "",
      isValid: (value) => validSecStatus.indexOf(value) > -1, valid: true
    };
  }

  validateEmail(email: string): boolean {
    return !!String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  validateDate(date: string): boolean {
    return (moment(date, "DD/MM/YYYY", true).isValid() || moment(date, "DD-MM-YYYY", true).isValid());
  }

  getSearchedOpportinuties(): IKHOpportunity[] {
    if (!this.searchValue) {
      return [];
    }
    const searchValue = this.searchValue.trim();
    return this.searchedOpportunities.filter(
      opportunity => (opportunity.opportunity_id.toLowerCase().indexOf(searchValue) > -1 ||
        opportunity.opportunity_name.toLowerCase().indexOf(searchValue) > -1 ||
        opportunity.account.toLowerCase().indexOf(searchValue) > -1)
    )
  }

  selectOpportunity(opportunity: IKHOpportunity) {
    this.selectedOpportunity = opportunity;
    this.form['title'].content = opportunity.opportunity_name;
    this.form['account'].content = opportunity.account;
    this.form['opportunity_id'].content = "OP# " + opportunity.opportunity_id;
    this.form['opportunity_lead'].content = opportunity.opportunity_lead;
    this.form['capability_unit'].content =
      this.capabilityUnits.includes(opportunity.capability_unit) ? opportunity.capability_unit : "";
    this.form['capability_unit_l1'].content =
      this.capabilityUnitsL1.includes(opportunity.capability_unit_l1) ? opportunity.capability_unit_l1 : "";
    this.form['created_date'].content = this.utils.formatStringDateToDDMMYYYY(opportunity.created_date);
    this.form['industry'].content =
      this.industries.includes(opportunity.industry) ? opportunity.industry : "";
  }

  getSearchedIndustries(): string[] {
    return this.industries.filter(
      i => i !== "N/A" && i.toLowerCase().includes(this.form['industry'].content.toLowerCase())
    );
  }

  getSearchedCapabilityUnits(): string[] {
    return this.capabilityUnits.filter(
      c => c !== "N/A" && c !== "Enterprise transformation" && c.toLowerCase().includes(
        this.form['capability_unit'].content.toLowerCase()
      )
    );
  }

  getSearchedCapabilityUnitsL1(): string[] {
    return this.capabilityUnitsL1.filter(
      c => c !== "N/A" && c.toLowerCase().includes(this.form['capability_unit_l1'].content.toLowerCase())
    );
  }

  resetPopin() {
    this.popinState = PopinState.AssetTypeChoice;
    this.file = undefined;
    this.selectedOpportunity = undefined;
    this.fileCorrelationID = undefined;
    this.form = {};
    this.searchValue = "";
  }

  chooseAssetTrack(assetChoice: AssetType): void {
    this.popinAssetTrack = assetChoice;
    this.initializeEmptyForm();
    this.popinState = PopinState.AddFile;
  }

  confirmUpload() {
    if (this.file) {
      this.popinState = PopinState.AnalysingFile;
      this.ikhProposalsService.postIkhProposalPreUpload(this.file).subscribe((res) => {
        this.fileCorrelationID = res.correlationID;
        if (this.popinAssetTrack === AssetType.ProjectAsset) {
          this.popinState = PopinState.SelectOpportunity;
        } else if (this.popinAssetTrack === AssetType.CompanyAsset) {
          this.popinState = PopinState.EditMetadata;
        }
      });
    }
  }

  confirmMetadata() {
    this.isFormValid = this.validateForm();
    if (this.isFormValid && this.file && this.fileCorrelationID) {
      this.popinState = PopinState.UploadingFile;
      const metadata: PostIKHProposalsMetadata = {
        title: this.form['title']?.content,
        account: this.form['account']?.content,
        opportunity_lead: this.form['opportunity_lead']?.content,
        opportunity_id: !!this.form['opportunity_id'] ? this.formatOpportunityIDforIKH(this.form['opportunity_id'].content) : undefined,
        capability_unit: this.form['capability_unit'].content,
        capability_unit_l1: this.form['capability_unit_l1'].content,
        industry: this.form['industry'].content,
        created_date: moment(this.form['created_date'].content, "DD-MM-YYYY").format(),
        sec_status: this.form['sec_status'].content,
        asset_categories: this.uploadAssetCategory,
      };
      this.ikhProposalsService.postIkhProposalUpload(
        this.fileCorrelationID,
        this.file.name,
        metadata,
        this.popinAssetTrack,
        this.uploadAssetCategory
      ).subscribe(() => {
        this.popinState = PopinState.Done;
      });
    }
  }

  formatOpportunityIDforIKH(opportunity_id: string): string {
    return opportunity_id.replace("OP# ", "").replace("OP#", "");
  }

  validateForm(): boolean {
    let isFormValid = true;
    for (const formComponent in this.form) {
      const isFormComponentValid = this.validateFormInput(this.form[formComponent])
      isFormValid = isFormValid && isFormComponentValid
    }
    return isFormValid;
  }

  validateFormInput(formInput: FormComponent): boolean {
    formInput.valid = formInput.isValid(formInput.content);
    return formInput.valid;
  }

  isPopinTopBarDisplayed(popinState: PopinState) {
    return (
      popinState !== PopinState.UploadingFile &&
      popinState !== PopinState.AnalysingFile &&
      popinState !== PopinState.Done &&
      popinState !== PopinState.Cancel
    )
  }

  selectFieldValue(field: string, value: string) {
    this.form[field].content = value;
    this.form[field].showDropdown = false;
    this.form[field].valid=true
  }
}
