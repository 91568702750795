export type IngestionStatus = "TODO" | "SUCCESS" | "FAILURE"

export interface AssetElement {
  name: string;
  opportunity_lead: string;
  contact_person: string;
  capability_unit: string;
  capability_unit_l1: string;
  industry: string;
  page: string;
  account: string;
  sharepoint_uri: string;
  unique_id: string;
  created_date: string;
  slides_urls: string[];
  id: number;
  slide_index: number;
  sec_status: string | null;
  slide_thumbnails_status: IngestionStatus;
  slide_embeddings_status: IngestionStatus;
  deck_embedding_status: IngestionStatus;
}

export const validSecStatus = ["SEC 0", "SEC 1", "SEC 2"]

export interface PostAssetElementResponse {
  elements: AssetElement[];
  query_abstract: string;
  vector_abstract: number[];
  search_id: number;
  error: boolean;
}

export interface PostAssetElementRequest {
  messages: string[],
  year_filer: string
}

export interface PostAssetElementLoadMoreRequest {
  query_abstract: string;
  vector_abstract: number[];
  search_id: number;
  year_filter: string;
}

export interface AssetElementWithIndex {
  assetElement: AssetElement;
  index: number;
}

export enum RatingValue {
  POSITIVE = "positive",
  NEGATIVE = "negative"
}

export  interface  PatchRatingRequest{
  result_rating: RatingValue;
}
