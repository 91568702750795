import {createAction, props} from "@ngrx/store";
import {GetRfpResponse, RfpAnalysisStatus, RfpDocument} from "../../services/api/rfp-assistant/model/model";

export const setRfpFetchInterval = createAction(
  '[Rfp-Assistant] set fetch interval',
  props<{rfpId: number, delay: boolean, rfpDetails?: GetRfpResponse}>()
);

export const setRfp = createAction(
  '[Rfp-Assistant] set rfp',
  props<{rfp: GetRfpResponse}>()
);

export const patchRfpName = createAction(
  '[Rfp-Assistant] patch Rfp Name',
  props<{rfpId: number, name: string}>()
);

export const postRfpDocuments = createAction(
  '[Rfp-Assistant] post Rfp Documents',
  props<{rfpId: number, documents: RfpDocument[]}>()
)

export const deleteRfpDocument = createAction(
  '[Rfp-Assistant] delete rfp document',
  props<{rfpId: number, documentId: number}>()
)

export const patchRfpAnalysisStatus = createAction(
  '[Rfp-Assistant] patch rfp analysis status',
  props<{rfpId: number, analysis_status: RfpAnalysisStatus}>()
)

export const deleteRfp = createAction(
  '[Rfp-Assistant] delete Rfp',
  props<{rfpId: number}>()
)


export const createSetRfpFetchInterval = (rfpId: number, delay: boolean, rfpDetails?: GetRfpResponse) => setRfpFetchInterval({rfpId, delay, rfpDetails});
export const createSetRfp = (rfp: GetRfpResponse) => setRfp({rfp});
export const createPatchRfpName = (rfpId: number, name: string) => patchRfpName({rfpId, name});
export const createPostRfpDocuments = (rfpId: number, documents: RfpDocument[]) => postRfpDocuments({rfpId, documents});
export const createDeleteRfpDocument = (rfpId: number, documentId: number) => deleteRfpDocument({rfpId, documentId});
export const createPatchRfpAnalysisStatus = (rfpId: number, analysis_status: RfpAnalysisStatus) => patchRfpAnalysisStatus({rfpId, analysis_status});
export const createDeleteRfp = (rfpId: number) => deleteRfp({rfpId});
