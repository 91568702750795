import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {
  AssetType,
  GetIkhProposalsMetadataResponse,
  PostIKHProposalsMetadata,
  PostIKHProposalsPreUploadResponse,
  UploadAssetCategory
} from "./model/model";


@Injectable({
  providedIn: 'root'
})
export class IkhProposalsService {

  constructor(
    public http: HttpClient
  ) {
  }

  postIkhProposalPreUpload(file: File): Observable<PostIKHProposalsPreUploadResponse> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<PostIKHProposalsPreUploadResponse>(environment.apiUrl + '/ikh-proposal/pre-upload', formData);
  }

  postIkhProposalUpload(correlation_id: string, filename: string, metadata: PostIKHProposalsMetadata, asset_type: AssetType, upload_asset_category: UploadAssetCategory): Observable<any> {
    return this.http.post(environment.apiUrl + "/ikh-proposal", {
      correlation_id,
      filename,
      metadata,
      asset_type,
      upload_asset_category
    });
  }

  getIkhProposalMetadata(): Observable<GetIkhProposalsMetadataResponse> {
    return this.http.get<GetIkhProposalsMetadataResponse>(environment.apiUrl + "/ikh-proposal/metadata");
  }

  getIkhProposalSlideThumbnail(unique_id: string, slide_index: number, large: boolean): Observable<Blob> {
    return this.http.get(
      environment.apiUrl + "/ikh-proposal/" + unique_id + "/thumbnails/" + slide_index + "?large=" + large,
      {responseType: 'blob'}
    );
  }

}
