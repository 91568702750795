import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RfpAssistantService} from "../../../../services/api/rfp-assistant/rfp-assistant.service";
import {GetRfpResponse} from "../../../../services/api/rfp-assistant/model/model";


@Component({
  selector: 'app-rfp-edit-text',
  templateUrl: './rfp-edit-text.component.html',
  styleUrls: ['rfp-edit-text.component.scss']
})
export class RfpEditTextComponent implements OnInit {

  @Input() rfpDetails: GetRfpResponse;
  @Input() documentId: number;
  @Output() popinCloser: EventEmitter<void> = new EventEmitter<void>();
  isPendingPost = false;

  textContent: string;
    textTitle: string;

  constructor(
    public RfpAssistantService: RfpAssistantService
  ) {
  }

  ngOnInit(): void {
    this.getTextDocumentContent();
  }

  getTextDocumentContent() {
    this.RfpAssistantService.getRfpTextDocumentContent(this.rfpDetails.rfp.id, this.documentId).subscribe(res => {
      this.textContent = res.text_content;
      this.textTitle = res.text_title;
    })
  }

  patchText() {
    this.RfpAssistantService.patchRfpTextDocumentContent(
      this.rfpDetails.rfp.id, this.documentId, this.textTitle + '.txt', this.textContent
    ).subscribe((res) => {
      const rfpDocsWithoutPatchedDoc = this.rfpDetails.documents.filter((doc) => doc.id !== this.documentId)
      this.rfpDetails.documents = [...rfpDocsWithoutPatchedDoc, res];
      this.rfpDetails.rfp.analysis_status = 'OUTDATED'
      this.popinCloser.emit();
    });

  }

}
