import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {PatchRatingRequest, PostAssetElementLoadMoreRequest, PostAssetElementResponse} from "./model/model";


@Injectable({
  providedIn: 'root'
})
export class SlideFinderService {

  constructor(public http: HttpClient) {
  }

  postSlideFinder(messages: string[], yearFilter: string, limit: number): Observable<PostAssetElementResponse> {
    let options = {}
    options = {params: new HttpParams().set("limit", limit)};
    return this.http.post<PostAssetElementResponse>(
      environment.apiUrl + "/ikh-proposal/slide-finder",
      {messages: messages, year_filter: yearFilter},
      options
    );
  };

  postSlideFinderLoadMore(
    query_abstract: string,
    vector_abstract: number[],
    search_id: number,
    year_filter: string,
    limit: number,
    skip: number = 0): Observable<PostAssetElementResponse> {
    let options = {params: new HttpParams().set("skip", skip).set("limit", limit)};
    let body: PostAssetElementLoadMoreRequest = {query_abstract, vector_abstract, search_id, year_filter};
    return this.http.post<PostAssetElementResponse>(
      environment.apiUrl + "/ikh-proposal/slide-finder/load-more",
      body,
      options
    );
  };

  patchRateResults(search_id: number, body: PatchRatingRequest): Observable<void> {
    return this.http.patch<void>(
      environment.apiUrl + "/ikh-proposal/slide-finder/" + search_id + "/rating", body
    )
  }
}
