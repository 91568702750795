<div *ngIf="isPending" class="spinner">
  <qmspinner *ngIf="isPending"></qmspinner>
</div>
<app-chat-llm
  *ngIf="!isPending"
  [placeholder]="placeholder"
  [resetButtonText]="resetButtonText"
  [areFilterDateRadiosDisabled]="isButtonRadioDisabled"
  [checkFilterYear]="checkFilterYear"
  (sendMessage)="postAsset($event, checkFilterYear)"
  (reset)="resetConversation($event)"
  (sendFilterYear)="checkFilterYear=$event"
  [canSendMessage]="!isPending"
>
</app-chat-llm>
