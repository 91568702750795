import {createReducer, on} from "@ngrx/store";
import {
  setRfpFetchInterval,
  setRfp,
  patchRfpName,
  postRfpDocuments,
  deleteRfpDocument,
  patchRfpAnalysisStatus,
  deleteRfp,
} from "./rfp-assistant.actions";
import {GetRfpResponse} from "../../services/api/rfp-assistant/model/model";

export interface RfpState {
  fetchingRfpIds: number[];
  rfps: { [rfpId: number]: GetRfpResponse }
}

const initialState: RfpState = {
  fetchingRfpIds: [],
  rfps: {}
};

export const rfpAssistantReducer = createReducer<RfpState>(
  initialState,
  on(setRfpFetchInterval, (state, {rfpId, rfpDetails}) => {
    const fetchingRfpIds = [...state.fetchingRfpIds];
    const rfps = {...state.rfps};
    if (rfpDetails && !rfps[rfpId]) {
      rfps[rfpId] = rfpDetails;
    }
    if (!fetchingRfpIds.includes(rfpId)) {
      fetchingRfpIds.push(rfpId);
    }
    return {...state, fetchingRfpIds, rfps};
  }),
  on(setRfp, (state, {rfp}) => ({
    ...state,
    fetchingRfpIds: state.fetchingRfpIds.filter(id => id !== rfp.rfp.id),
    rfps: {...state.rfps, [rfp.rfp.id]: rfp}
  })),
  on(patchRfpName, (state, {rfpId, name}) => ({
    ...state,
    rfps: {
      ...state.rfps, [rfpId]: {...state.rfps[rfpId], rfp: {...state.rfps[rfpId].rfp, name}}
    }
  })),
  on(postRfpDocuments, (state, {rfpId, documents}) => ({
    ...state,
    rfps: {
      ...state.rfps, [rfpId]: {
        ...state.rfps[rfpId],
        rfp: {...state.rfps[rfpId].rfp}, analysis_status: "OUTDATED",
        documents: [...state.rfps[rfpId].documents, ...documents]
      }
    }
  })),
  on(deleteRfpDocument, (state, {rfpId, documentId}) => ({
    ...state,
    rfps: {
      ...state.rfps, [rfpId]: {
        ...state.rfps[rfpId],
        rfp: {...state.rfps[rfpId].rfp, analysis_status: "OUTDATED"},
        documents: [...state.rfps[rfpId].documents.filter(
          doc => doc.id != documentId
        )]
      }
    }
  })),
  on(patchRfpAnalysisStatus, (state, {rfpId, analysis_status}) => ({
    ...state,
    rfps: {
      ...state.rfps, [rfpId]: {...state.rfps[rfpId], rfp: {...state.rfps[rfpId].rfp, analysis_status}}
    }
  })),
  on(deleteRfp, (state, {rfpId}) => ({
    ...state,
    fetchingRfpIds: [...state.fetchingRfpIds.filter((id) => id != rfpId)]
  })),
);
