<div (window:resize)="onResize()">
  <qmtopbar
    applicationName=""
    [authParam]="authParam"
    [navigationItems]="canAccessTool ? navigationItems : []"
    [imageSource]="lightTopBar? '/assets/logo_nom_noir.svg' : '/assets/logo_nom_blanc.svg'"
    (onLogoClick)="navigateToPage('')"
    [feedbackIcons]="true"
    (openFeedback)="openWindow('feedback')"
    (openHelp)="openWindow('help')"
    [isLightTheme]="lightTopBar"
    (clickedProfile)="navigateToPage('/profile')"
  >
  </qmtopbar>
</div>
<div *ngFor="let rfpId of doneRfpIds" [class]="'toaster-container ' +(displayRfpIds.indexOf(rfpId) > -1 ? 'show': '')">
  <qmtoaster
    title="RFP Assistant"
    color="var(--primary-color)"
    description="The analysis of your RFP is complete."
    logo="/assets/logo.svg"
    (close)="closeRfpToaster(rfpId)"
    (discoverIt)="discoverRfp(rfpId)"
  >
  </qmtoaster>
</div>
<router-outlet (activate)="onActivate($event)"></router-outlet>
