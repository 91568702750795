import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {environment} from "../../environments/environment";
import {map} from "rxjs/operators";
import {AuthService} from "@quantmetry/api-services";

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  public adminTeamsGroupIds?: string[];

  public constructor(public authService: AuthService, private router: Router) {
  }

  hasAccess(): boolean {
    const teamsGroupIds: string[] = environment.adminTeamsGroupIds;
    let access: boolean;
    if (teamsGroupIds.length === 0) {
      access = true;
    } else if (!this.adminTeamsGroupIds) {
      access = false;
    } else {
      access = this.adminTeamsGroupIds.some(groupId => teamsGroupIds.includes(groupId));
    }
    if (!access) {
      this.router.navigate(["/"]);
    }
    return access;
  }

  canActivate(): Observable<boolean> {
    if (environment.adminTeamsGroupIds.length === 0) {
      return of(true);
    }
    if (this.adminTeamsGroupIds != null) {
      return of(this.hasAccess());
    }
    return this.authService.getUserGroupIds().pipe(map(teamsGroupIds => {
      this.adminTeamsGroupIds = teamsGroupIds;
      return this.hasAccess();
    }));
  }

}
