export enum PopinState {
  AssetTypeChoice,
  AddFile,
  SelectOpportunity,
  AnalysingFile,
  EditMetadata,
  Cancel,
  UploadingFile,
  Done
}

export interface FormComponent {
  content: string;
  valid?: boolean;
  isValid: (value: string) => boolean;
  showDropdown?: boolean;
}
