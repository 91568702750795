import {userReducer, UserState} from "./user/user.reducer";
import {rfpAssistantReducer, RfpState} from "./rfp-assistant/rfp-assistant.reducer";

export interface StoreState {
  user: UserState,
  rfpAssistant: RfpState
}

export const reducers = {
  user: userReducer,
  rfpAssistant: rfpAssistantReducer
};
