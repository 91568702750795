<app-header
  [headerTitle]="'RFP Assistant'"
  [headerDescription]="'Speed up your response process by chatting with your RFP and finding the most relevant assets'"
  [headerBackgroundColor]="'rfpAssistant'"
>
</app-header>
<div class="rfp-assistant-home-main-container">
  <div
    class="home-page-rfp-assistant-container"
  >
    <div class="home-page-rfp-assistant-creation" *ngIf="rfpsList?.length == 0">
      <div class="body2 description">
        Find experts and assets you need by sharing information from your RFP.
      </div>
      <div class="start-rfp-button">
        <button
          qm
          class="primary large"
          (click)="startRfpCreation()"
          [isPending]="creatingRfp"
        > Create a new RFP analysis
        </button>
      </div>
    </div>

    <div *ngIf="rfpsList && rfpsList.length > 0">
      <div class="top-rfp-section">
        <h4 style="margin: 0">
          My RFP
        </h4>
        <div class="search-container">
          <span class="qm-icon-search-02 search-icon"></span>
          <input class="body2 search-input"
                 id="input-filter"
                 placeholder="Search"
                 [(ngModel)]="searchValue"
                 (keyup)="applyFilterSearch($event.target)"
                 qm>
        </div>
      </div>

      <div class="home-page-rfp-assistant-last-rfp-container">
        <div class="home-page-rfp-assistant-creation-container">
          <div class="body2 description">
            Find experts and assets you need by sharing information from your RFP.
          </div>
          <div class="start-rfp-button">
            <button
              qm
              class="primary large"
              (click)="startRfpCreation()"
              [isPending]="creatingRfp"
            > Create a new RFP analysis
            </button>
          </div>
        </div>
        <div
          *ngFor="let rfp of filteredRfps"
          class="last-rfp-item-container">
          <div class="last-rfp-item-header">
            <div class="subtitle bold last-rfp-item-header-title">
              {{ rfp.name === "" ? "Rfp's name empty" : rfp.name }}
            </div>
            <div>
              {{ getYearFromDate(rfp.creation_date) }}
            </div>
          </div>
          <div class="body3 last-rfp-item-summary">
            <markdown>
              {{ rfp.summary === "" ? "Rfp's summary empty" : rfp.summary }}
            </markdown>
          </div>
          <div class="last-rfp-item-buttons-container">
            <button
              qm
              class="secondary"
              (click)="showRfpDetails(rfp.id)"
            >
              Open analysis
            </button>
            <button
              qm
              class="tertiary"
              icon="trash-02"
              (click)="deleteRfp(rfp.id)"
              [isPending]="deletingRfpIds.indexOf(rfp.id) > - 1"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
      <div class="scroll-top-button">
        <button qm
                class="tertiary body2 medium"
                icon="arrow-up-contained-02"
                (click)="onScrollToTop()"
        > Back to the top
        </button>
      </div>
    </div>
  </div>
</div>
