import {StoreState} from "../reducers";
import {createSelector} from "@ngrx/store";
import {RfpState} from "./rfp-assistant.reducer";
import {GetRfpResponse} from "../../services/api/rfp-assistant/model/model";

const getRfpStore = (state: StoreState) => state.rfpAssistant;

export const selectRfp: (state: any, rfpId: number) => GetRfpResponse | undefined = createSelector(
  getRfpStore,
  (state: RfpState, rfpId: number) => state.rfps[rfpId]
);

export const selectAllRfps : (state: any) => {[key: number]: GetRfpResponse} = createSelector(
  getRfpStore,
  (state: RfpState) => state.rfps
);
