<div class="chat-llm-container">
  <div class="chat-side-container">
    <div class="reset-button-container">
      <button class="reset-button transparent" icon="edit-contained"
              (click)="sendEventReset()" *ngIf="resetButtonText" qm> {{ resetButtonText }}
      </button>
    </div>
  </div>
  <div class="chatbox-container">
    <textarea class="input-message"
              [maxlength]="questionMaxLength"
              [ngClass] = "{'dark': isDarkMode, 'white': !isDarkMode}"
              [(ngModel)]="message"
              placeholder="{{placeholder}}"
              (keydown.enter)="sendEventMessage($event)"
              (ngModelChange)="checkTextAreaIsEmpty()"
              [disabled]="isAnswerPending"
              (keydown)="autoGrowTextZone($event)"
              qm
    ></textarea>
    <div [class]="areFilterDateRadiosDisplayed? 'prompt-footer caption' : ''">
      <div class="filter-date-container"  *ngIf="areFilterDateRadiosDisplayed">
        Don't show assets older than
        <input type="radio" style="margin: 0"
               [checked]="checkFilterYear === '2024'"
               [disabled]="areFilterDateRadiosDisabled"
               (click)="setFilterYear('2024')">
        <div [class]="checkFilterYear === '2024'? 'date-underlined' : ''">
          2024
        </div>
        <input type="radio" class="caption" style="margin: 0"
               [checked]="checkFilterYear === '2023'"
               [disabled]="areFilterDateRadiosDisabled"
               (click)="setFilterYear('2023')">
        <div [class]="checkFilterYear === '2023'? 'date-underlined' : ''">
          2023
        </div>
        <input type="radio" class="caption" style="margin: 0"
               [checked]="checkFilterYear === '2022'"
               [disabled]="areFilterDateRadiosDisabled"
               (click)="setFilterYear('2022')">
        <div [class]="checkFilterYear === '2022'? 'date-underlined' : ''">
          2022
        </div>
        <input type="radio" class="caption" style="margin: 0"
               [checked]="checkFilterYear === '2021'"
               [disabled]="areFilterDateRadiosDisabled"
               (click)="setFilterYear('2021')">
        <div [class]="checkFilterYear === '2021'? 'date-underlined' : ''">
          2021
        </div>
      </div>
      <div class="character-count">
        {{ message ? message.length : 0 }}/{{ questionMaxLength }}
      </div>
    </div>
  </div>
  <div class="chat-side-container">
    <div class="button-container">
      <button class="send-button" icon="send-01"
              (click)="sendEventMessage($event)" [disabled]="isTextAreaEmpty" qm></button>
    </div>
  </div>
</div>
