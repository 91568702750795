<div class="search-answer-container" *ngIf="isPending || query">
  <div class="text-holder">
    <img *ngIf="query && !isPending" src="/assets/logo.svg" alt="logo"/>
    <div *ngIf="isPending" class="spinner">
      <qmspinner *ngIf="isPending"></qmspinner>
    </div>
    <div *ngIf="query && !isPending" class="text-container">
      <div class="text-cropped">
        {{ checkFilterYear === '' ? query : query + ', since ' + checkFilterYear }}
      </div>
    </div>
  </div>
  <div class="button-container" *ngIf="query">
    <button class="choice-button reset transparent" icon="edit-contained"
            (click)="resetConversation($event)"
            qm> {{ resetButtonText }}
    </button>
    <button class="choice-button" icon="edit-contained"
            (click)="refineConversation($event)"
            qm> Refine search
    </button>
  </div>
</div>
<app-chat-llm
  *ngIf="!isPending && !query"
  [placeholder]="placeholder"
  [resetButtonText]="resetButtonText"
  [areFilterDateRadiosDisabled]="isButtonRadioDisabled"
  [checkFilterYear]="checkFilterYear"
  (sendMessage)="postAsset($event, checkFilterYear)"
  (reset)="resetConversation($event)"
  (sendFilterYear)="checkFilterYear=$event"
  [canSendMessage]="!isPending"
>
</app-chat-llm>
