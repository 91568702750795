export interface PostIKHProposalsPreUploadResponse {
  metadata: { [key: string]: string };
  correlationID: string;
}

export interface PostIKHProposalsMetadata {
  title?: string;
  account?: string;
  opportunity_lead?: string;
  opportunity_id?: string;
  capability_unit: string;
  capability_unit_l1: string;
  industry: string;
  created_date: string;
  sec_status: string;
  asset_categories: string;
}

export type UploadAssetCategory = 'Proposals' | 'Credentials' | 'Deliverables' | 'Project Summary' |
  'Capgemini Studies' | 'Invent Approaches or Methods' | 'Training' | 'Marketing and Offers' | 'Knowledge Nugget';

export interface PostIkhProposalsUploadRequest {
  correlation_id: string;
  filename: string;
  metadata: PostIKHProposalsMetadata;
  asset_type: AssetType;
  upload_asset_category: UploadAssetCategory
}

export interface GetIkhProposalsMetadataResponse {
  industries: string[];
  capability_units: string[];
  capability_unit_l1: string[];
}

export enum AssetType {
  ProjectAsset = "Project asset",
  CompanyAsset = "Company asset"
}
