import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {
  GetIkhProposalsResponse, IkhProposalsFailureUpload, IngestionJobType, PostSearchActionLogRequest, SearchAction,
} from "./model/model";
import {Mode} from "../../../components/asset-element-snippet/asset-element-snippet.component";

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(public http: HttpClient) {
  }

  getIkhProposals(): Observable<GetIkhProposalsResponse> {
    return this.http.get<GetIkhProposalsResponse>(environment.apiUrl + '/ikh-proposal');
  }

  deleteIkhProposal(id: string): Observable<any> {
    return this.http.delete(environment.apiUrl + '/ikh-proposal/' + id);
  }

  deleteIkhProposals(): Observable<any> {
    return this.http.delete(environment.apiUrl + "/ikh-proposal");
  }

  postSearchActionLog(
    sharepoint_uri: string,
    slide_index: number,
    search_id: number,
    search_action: SearchAction,
    search_mode: Mode
  ): void {
    this.http.post<PostSearchActionLogRequest>(
      environment.apiUrl + '/admin/search-action/logs',
      {
        sharepoint_uri,
        slide_index,
        search_id,
        search_action,
        search_mode
      }
    ).subscribe();
  }

  public downloadAssetRetrieverSlideFinderLogs(): Observable<Blob> {
    return this.http.get(environment.apiUrl + "/admin/search-action/logs", {responseType: "blob"});
  }

  public downloadUsageLogs(): Observable<Blob> {
    return this.http.get(environment.apiUrl + "/admin/activity/logs", {responseType: "blob"});
  }

  public downloadErrorLogs(): Observable<Blob> {
    return this.http.get(environment.apiUrl + "/admin/error/logs", {responseType: "blob"});
  }

  public getDBVersion(): Observable<string> {
    return this.http.get(environment.apiUrl + "/admin/db-version", {responseType: "text"});
  }

  public resetIngestionFailures(ingestionJobType: IngestionJobType): Observable<null> {
    return this.http.get<null>(environment.apiUrl + "/admin/reset-ingestion-failures/" + ingestionJobType);
  }

  public getIkhProposalsFailureUploads(): Observable<IkhProposalsFailureUpload[]> {
    return this.http.get<IkhProposalsFailureUpload[]>(environment.apiUrl + "/admin/failure-uploads");
  }

  public deleteIkhProposalsFailureUpload(failureId: number): Observable<null> {
    return this.http.delete<null>(environment.apiUrl + "/admin/failure-upload/" + failureId);
  }

  public setIkhProposalsFailureUploadToProcessed(failureId: number): Observable<null> {
    return this.http.get<null>(environment.apiUrl + "/admin/process-failure-upload/" + failureId);
  }
}
