<div (click)="isGpt4DropdownOpen = !isGpt4DropdownOpen" class="model-choice-button body2 medium">
  {{ gpt4 ? 'GPT 4' : 'GPT 3.5'}}
  <span class="qm-icon-chevron-down"></span>
</div>
<div *ngIf="isGpt4DropdownOpen" class="model-choice-dropdown body3">
  <div (click)="toggleGpt4Model(false)"
       class="model-choice-dropdown-item"
       style="border-top-left-radius: 7px; border-top-right-radius: 7px">
    GPT 3.5
  </div>
  <div style="border-top: solid 1px var(--border-grey-strong)"></div>
  <div (click)="toggleGpt4Model(true)"
       class="model-choice-dropdown-item"
       style="border-bottom-left-radius: 7px; border-bottom-right-radius: 7px">
    GPT 4
  </div>
</div>
<div [class]="'history-mask ' + (displayHistory ? 'history-mask-displayed' : 'history-mask-hidden')"
     (click)="clickOnHistoryMask()">
</div>
<div [class]="'history-container ' + (displayHistory ? 'history-container-displayed' : 'history-container-hidden')">
  <div class="button-column" (click)="displayHistory ? switchDisplayHistory($event) : ''">
    <div class="button-container" *ngIf="!displayHistory" (click)="switchDisplayHistory($event)">
      <span class="qm-icon-chevron-double-right"></span>
      Chat history
    </div>
  </div>
  <div class="main-column">
    <div class="hide-container">
      <span (click)="switchDisplayHistory()" class="qm-icon-chevron-double-left"></span>
    </div>
    <div class="title">
      History
    </div>
    <div *ngIf="isFetchingConversations" class="spinner-holder">
      <qmspinner></qmspinner>
    </div>
    <div class="history-element"
         (click)="selectConversation(element)"
         *ngFor="let element of conversations">
      <div class="history-name">
        {{ element.name ? element.name : 'Unnamed history' }}
      </div>
      <div class="history-arrow" (click)="deleteConversation(element)">
        <span class="qm-icon-trash-01"></span>
      </div>
      <div class="history-arrow">
        <span class="qm-icon-arrow-right"></span>
      </div>
    </div>
  </div>
</div>
<div class="top-bar-placeholder">

</div>
<div class="middle-placeholder" #messages>
  <div class="middle-side-container"></div>
  <div class="middle-container">
    <div class="empty-container" *ngIf="!conversation || !conversation.messages.length">
      <div class="icon-container">
        <img src="assets/logo.svg" alt="icon">
      </div>
      <div class="title-container">
        How can I help you today ?
      </div>
      <div class="subtitle-container">
        Ask me any question you would ask ChatGPT,
        <br/>
        I will answer in a secure and compliant way.
      </div>
      <div class="specific-gpts-container">
        <div class="specific-gpt-container body2 medium" (click)="newGptExpertConversation('EXPERT_MARKET_SIZING')">
          Market sizing
        </div>
        <div class="specific-gpt-container body2 medium" (click)="newGptExpertConversation('EXPERT_RESEARCH_BRIEF')">
          Research brief
        </div>
      </div>
    </div>
    <div *ngFor="let message of conversation.messages; let last = last;">
      <div *ngIf="message.isUser" class="question-container">
        <div *ngIf="userPicture" class="profile-picture"><img [src]="userPicture" alt="profile userPicture"></div>
        <div *ngIf="!userPicture" class="profile-picture"><span
          class="qm-icon-user-profile-circle profile-picture-qmtopbar"></span></div>
        <div class="question-bubble-container">
          {{ message.text }}
        </div>
      </div>
      <div *ngIf="isAnswerPending && last" class="spinner">
        <qmspinner></qmspinner>
      </div>
      <div *ngIf="!message.isUser" class="answer-container">
        <div class="ai-icon">
          <img src="/assets/logo.svg" alt="just-ai">
        </div>
        <div class="answer-right-container">
          <markdown class="answer-bubble-container">
            {{ message.text }}
          </markdown>
          <div *ngIf="!message.isUser && last" class="answer-right-bottom-container">
            <div class="options-container">
              <button class="reload-button" (click)="resendLastMessage()" icon="arrow-refresh-01" qm></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="lastQuestionReturnedError" class="answer-container">
      <span class="qm-icon-alert-circle error"></span>
      <div class="error">
        Sorry, but Just AI was unable to find an answer to your question. Please, try again or reformulate.
      </div>
    </div>
  </div>
  <div class="middle-side-container"></div>
</div>
<app-chat-llm
  [placeholder]="placeholder"
  [resetButtonText]="conversation.id ? resetButtonText: undefined"
  (sendMessage)=askQuestion($event)
  (reset)="resetConversation()"
  [areFilterDateRadiosDisplayed]="false"
  [canSendMessage]="canSendMessage"
>
</app-chat-llm>
