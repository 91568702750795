<div [class]="'container ' + (assetElement ? 'not-pending' : 'pending')">
  <div class="miniature-container">
    <img alt="miniature"
         *ngIf="assetElement && thumbnails.getThumbnail(assetElement.unique_id, assetElement.slide_index, false)"
         [src]="thumbnails.getThumbnail(assetElement.unique_id, assetElement.slide_index, false)" >
    <div *ngIf="assetElement && !thumbnails.getThumbnail(assetElement.unique_id, assetElement.slide_index, false)" class="gradient"></div>
  </div>
  <div *ngIf="mode === 'Slide Finder'" class="gray-border">

  </div>
  <div *ngIf="mode === 'Asset Retriever'" class="asset-details">
    <div class="asset-name">
      {{ utils.getFileNameWithoutExtension(assetElement?.name)}}
    </div>
    <div class="asset-date">
      {{ assetElement?.account }}
    </div>
    <div class="asset-metadata-holder">
      <span *ngIf="assetElement && assetElement.industry" class="metadata">{{assetElement.industry}}</span>
      <span *ngIf="assetElement && assetElement.capability_unit" class="metadata">{{assetElement.capability_unit}}</span>
      <span *ngIf="assetElement && assetElement.capability_unit_l1" class="metadata">{{assetElement.capability_unit_l1}}</span>
    </div>
  </div>
  <div class="details-buttons-container">
    <button qm *ngIf="assetElement"
            class="secondary"
            (click)="openDocument()">
      Open document
    </button>
    <button qm *ngIf="assetElement"
            class="tertiary"
            (click)="viewDetails.emit()">
      <span class="qm-icon-eye-open"></span>View details
    </button>
    <div *ngIf="!assetElement" class="pending-button"></div>
    <div *ngIf="!assetElement" class="pending-button"></div>
  </div>
</div>
