import {createAction, props} from "@ngrx/store";
import UserType from "@quantmetry/api-services/lib/services/auth/models/user.type";

export const fetchUser = createAction(
  '[User] fetch user'
);

export const setUser = createAction(
  '[User] set user',
  props<{ user: UserType }>()
);

export const setTeamsGroupIds = createAction(
  '[User] set teams group ids',
  props<{ teamsGroupIds: string[] }>()
);


export const createFetchUserAction = () => fetchUser();
export const createSetUserAction = (user: UserType) => setUser({user});
export const createSetTeamsGroupIds = (teamsGroupIds: string[]) => setTeamsGroupIds({teamsGroupIds});
