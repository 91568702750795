import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {delay, filter, map, mergeMap, timeout} from 'rxjs/operators';
import {setRfpFetchInterval, setRfp} from "./rfp-assistant.actions";
import {RfpAssistantService} from "../../services/api/rfp-assistant/rfp-assistant.service";
import {pipe} from "rxjs";



@Injectable()
export class RfpAssistantEffects {

  getRfpAssistant = (action: ReturnType<typeof setRfpFetchInterval>, index: number) => this.rfpAssistantService.getRfp(action.rfpId).pipe(
    map(rfp => {
      if (rfp.rfp.analysis_status !== "TO_DO" && rfp.rfp.analysis_status !== "IN_PROGRESS") {
        return setRfp({rfp: rfp});
      } else {
        return setRfpFetchInterval({rfpId: action.rfpId, delay: true, rfpDetails: rfp});
      }
    }))

  fetchRfpAssistantWithDelay = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof setRfpFetchInterval>>(setRfpFetchInterval.type),
      filter(action => action.delay),
      delay(5000),
      mergeMap(this.getRfpAssistant)
    )
  );

  fetchRfpAssistantWithoutDelay = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof setRfpFetchInterval>>(setRfpFetchInterval.type),
      filter(action => !action.delay),
      mergeMap(this.getRfpAssistant)
    )
  );

  constructor(
    private actions$: Actions,
    public rfpAssistantService: RfpAssistantService,
  ) {
  }

}
