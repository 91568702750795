import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {GetIkhOpportunitiesSearchResponse} from "./model/model";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class IkhOpportunitiesService {

  constructor(
    public http: HttpClient
  ) { }

  searchIkhOpportunities(search: string): Observable<GetIkhOpportunitiesSearchResponse> {
    return this.http.get<GetIkhOpportunitiesSearchResponse>(environment.apiUrl + "/ikh-opportunities/search?q=" + search);
  }
}
