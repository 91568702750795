import {createReducer, on} from "@ngrx/store";
import {fetchUser, setTeamsGroupIds, setUser} from "./user.actions";
import UserType from "@quantmetry/api-services/lib/services/auth/models/user.type";

export interface UserState {
  user?: UserType;
  teamsGroupIds?: string[];
}

const initialState: UserState = {};

export const userReducer = createReducer<UserState>(
  initialState,
  on(fetchUser, (state) => ({...state})),
  on(setUser, (state, {user}) => ({...state, user})),
  on(setTeamsGroupIds, (state, {teamsGroupIds}) => ({...state, teamsGroupIds})),
);
