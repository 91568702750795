import {UserState} from "./user.reducer";
import {StoreState} from "../reducers";
import {createSelector} from "@ngrx/store";
import {environment} from "../../../environments/environment";
import UserType from "@quantmetry/api-services/lib/services/auth/models/user.type";

const getUserStore = (state: StoreState) => state.user;

export const selectUser: (state: any) => UserType | undefined = createSelector(
  getUserStore,
  (state: UserState) => state.user
);

export const selectCanAccessTool: (state: any) => boolean = createSelector(
  getUserStore,
  (state: UserState) => {
    const teamsGroupIds: string[] = environment.teamsGroupIds;
    if(teamsGroupIds.length === 0){
      return true;
    } else if(!state.teamsGroupIds){
      return false;
    }
    return state.teamsGroupIds.some(groupId => teamsGroupIds.includes(groupId));
  }
)
