import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {
  GetPrivateGptConversationResponse,
  GetPrivateGptConversationsResponse,
  Message,
  PrivateGptResponse
} from "./model/model";


@Injectable({
  providedIn: 'root'
})
export class SecureChatGptService {

  constructor(public http: HttpClient) {
  }

  askQuestion(
    text: string,
    pastConversation: Message[],
    gpt4: boolean,
    conversationType: string,
    conversationID?: string,
  ): Observable<PrivateGptResponse> {
    let options = {params: new HttpParams().set("gpt_4", gpt4)};
    if (conversationID) {
      options.params = options.params.set("conversation_id", conversationID);
    }
    return this.http.post<PrivateGptResponse>(
      environment.apiUrl + "/secure-chatgpt",
      {
        text: text,
        past_conversation: pastConversation,
        conversation_type: conversationType
      },
      options
    );
  };

  getConversations(): Observable<GetPrivateGptConversationsResponse> {
    return this.http.get<GetPrivateGptConversationsResponse>(environment.apiUrl + '/secure-chatgpt/conversation');
  }

  getConversation(conversationId: number): Observable<GetPrivateGptConversationResponse> {
    return this.http.get<GetPrivateGptConversationResponse>(
      environment.apiUrl + '/secure-chatgpt/conversation/' + conversationId
    );
  }

  deleteConversation(conversationId: number): Observable<any> {
    return this.http.delete(environment.apiUrl + "/secure-chatgpt/conversation/" + conversationId);
  }

}
