import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {Router} from "@angular/router";
import {UserProfileService} from "../../services/api/user-profile/user-profile.service";
import {PatchUserProfileRequest, UserProfile, UserSharedAssets} from "../../services/api/user-profile/model/model";
import {AuthService} from "@quantmetry/api-services";
import UserType from "@quantmetry/api-services/lib/services/auth/models/user.type";
import {AssetElementWithIndex} from "../../services/api/slide-finder/model/model";
import {Utils} from "../../utils/utils";

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit {

  editing_expertise = false;
  editingDescription = false;
  user: UserProfile;
  descriptionMaxLength = 300;
  picture: string;
  message = "pending connection";
  userInfo: UserType;
  list_expertises: string[] = [];
  CompletedSharedAssets: UserSharedAssets = {assets: []}
  PendingSharedAssets: UserSharedAssets = {assets: []}
  expandedAssetElement?: AssetElementWithIndex;
  utils: Utils = new Utils();
  @Input() allowAnyText: (value: string) => string = (element) => "Add " + element;
  @Output() changeExpertisesTagLists = new EventEmitter<{ expertises: string[], }>();
  @Output() openDetailsPage: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeDetailsPage: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public userProfileService: UserProfileService,
    public router: Router,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.getUser();
    this.authService.getUserPicture().subscribe(res => {
      this.picture = res;
    });
    this.authService.getUserInformations().subscribe(res => {
      this.userInfo = res;
    });
    this.getSharedAssets();
  }

  navigateToShareAssets() {
    this.router.navigate(["/new-asset/"])
  }

  patchUserProfile() {
    const body: PatchUserProfileRequest = {
      description: this.user.description,
      expertises: this.list_expertises
    };
    this.userProfileService.patchUserProfile(body).subscribe((res) => {
      this.user.description = body.description;
      this.user.expertises = body.expertises;
    });
  }

  getUser() {
    this.userProfileService.getUserInfo().subscribe((res) => {
        this.user = res;
        if (!this.list_expertises.length) {
          this.list_expertises = this.user.expertises ? [...this.user.expertises] : [];
        }
      }
    )
  }

  getTagListToStringFunction() {
    return (tag: string) => tag;
  }

  setTagsLists(event: string[]): void {
    if (event.length > 4) {
      this.list_expertises = event.slice(0, 4);
    } else {
      this.list_expertises = event;
    }
    this.changeExpertisesTagLists.emit({
      expertises: this.list_expertises
    });
  }
  getSharedAssets() {
    this.userProfileService.getSharedAssets().subscribe((res) => {
      let allAssets = res.assets;
      for (var asset of allAssets) {
        if (asset.deck_embedding_status === "TODO" || asset.slide_embeddings_status === "TODO" || asset.slide_thumbnails_status === "TODO") {
          this.PendingSharedAssets.assets.push(asset);
        } else {
          this.CompletedSharedAssets.assets.push(asset);
        }
      }
    });
  }

  viewElementDetails(event: AssetElementWithIndex) {
    this.openDetailsPage.emit();
    this.expandedAssetElement = event;
  }

  backToPrincipalPage() {
    this.closeDetailsPage.emit();
    this.expandedAssetElement = undefined;
  }
}
