import {Component, OnInit} from '@angular/core';
import {RfpAssistantService} from "../../services/api/rfp-assistant/rfp-assistant.service";
import {RfpModel} from "../../services/api/rfp-assistant/model/model";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {createDeleteRfp} from "../../store/rfp-assistant/rfp-assistant.actions";

@Component({
  selector: 'app-rfp-assistant',
  templateUrl: './rfp-assistant.component.html',
  styleUrls: ['./rfp-assistant.component.scss']
})
export class RfpAssistantComponent implements OnInit {

  nbOfRfps: number;
  rfpsList: RfpModel[];
  filteredRfps: RfpModel[]
  rfp: RfpModel;
  createdRfp: number;
  searchValue = "";
  link = "rfp-assistant";
  deletingRfpIds: number[] = [];
  creatingRfp = false;

  constructor(
    public RfpAssistantService: RfpAssistantService,
    private router: Router,
    public route: ActivatedRoute,
    public store: Store
  ) {
  }

  ngOnInit(): void {
    this.getAllRfps();
  }

  startRfpCreation() {
    this.creatingRfp = true;
    this.RfpAssistantService.postRfp().subscribe((res) => {
      this.createdRfp = res.id;
      this.showRfpDetails(this.createdRfp);
    });
  }

  getAllRfps() {
    this.RfpAssistantService.getRfps().subscribe((res) => {
      this.rfpsList = res.list_rfps.sort((a:RfpModel, b: RfpModel) => {
        return new Date(a.creation_date) < new Date(b.creation_date)? 1 : -1});
      this.nbOfRfps = res.list_rfps.length;
      this.updateFilter();
    });
  }

  showRfpDetails(rfp_id: number) {
    this.router.navigate(['/rfp-assistant/', rfp_id]);

  }

  deleteRfp(id: number){
    this.deletingRfpIds.push(id);
    this.store.dispatch(createDeleteRfp(id));
    this.RfpAssistantService.deleteRfp(id).subscribe((res) => {
      this.getAllRfps();
      this.deletingRfpIds = this.deletingRfpIds.filter(rfpId => rfpId != id);
    });
  }

  getYearFromDate(date: string | undefined): string {
    if (date == undefined) {
      return ""
    }
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      return "";
    }
    return parsedDate.getFullYear().toString();
  }

  onScrollToTop(): void {
    const rfpSection = document.querySelector('.top-rfp-section');
    if (rfpSection) {
      rfpSection.scrollIntoView({behavior: 'smooth'})
    }
  }

  applyFilterSearch(target: EventTarget | null) {
    if (!target || !(target instanceof (HTMLInputElement))) {
      this.searchValue = "";
    } else {
      this.searchValue = target.value;
    }
    this.updateFilter();
  }

  updateFilter(): void {
    if (this.searchValue == "") {
      this.filteredRfps = this.rfpsList;
    } else {
      this.searchValue
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .split(" ")
        .forEach(word => {
          this.filteredRfps = this.rfpsList.filter(
            rfp => rfp.name
              .toLowerCase().
              normalize("NFD").
              replace(/[\u0300-\u036f]/g, "").
              includes(word)
          );
        });
    }
  }
}
